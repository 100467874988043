/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type ImageType = typeof ImageType[keyof typeof ImageType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImageType = {
  invite_campaign_thumbnail: 'invite_campaign_thumbnail',
  invite_campaign_banner: 'invite_campaign_banner',
  offer_thumbnail: 'offer_thumbnail',
  offer_banner: 'offer_banner',
  offer_logo: 'offer_logo',
  market_banner: 'market_banner',
  partner_app_logo: 'partner_app_logo',
  partner_app_image: 'partner_app_image',
  step_campaign_logo: 'step_campaign_logo',
  recommended_image: 'recommended_image',
} as const;
