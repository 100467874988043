import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { ImageType } from "~/api/model";
import {
  getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryOptions,
  useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut,
} from "~/api/partner-app/partner-app.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/partner-apps/$partnerAppId")({
  loader: ({ context: { queryClient }, params: { partnerAppId } }) => {
    return queryClient.ensureQueryData(getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryOptions(partnerAppId));
  },
  component: () => <UpdatePartnerAppPage />,
});

const UpdatePartnerAppPage = () => {
  const navigate = useNavigate();

  const partnerAppCampaignId = Route.useParams().partnerAppId;

  const { data: partnerAppCampaign, isFetching } = useSuspenseQuery(
    getGetPartnerAppByIdV1PartnerAppsPartnerAppIdGetQueryOptions(partnerAppCampaignId),
  );

  const updatePartnerAppMutation = useUpdatePartnerAppV1PartnerAppsPartnerAppIdPut({
    mutation: {
      onSuccess: () => {
        toast("Partner app campaign was successfully updated!");
        navigate({ to: `/partner-apps?status=${partnerAppCampaign.status}&country=${partnerAppCampaign.country}` });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updatePartnerAppMutation.mutateAsync({
      partnerAppId: partnerAppCampaignId,
      data: partnerAppCampaign,
    });
  };

  const page: PageConfig = {
    title: "Edit partner app campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => (partnerAppCampaign.country = data),
        value: partnerAppCampaign.country,
        options: getCountryOptions(),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: partnerAppCampaign.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (partnerAppCampaign.title = event.target.value),
        row: true,
        required: true,
      },
      {
        name: "description",
        label: "Description:",
        type: "text-editor",
        helpText: "Campaign description.",
        value: partnerAppCampaign.description,
        change: (data: any) => (partnerAppCampaign.description = data),
        row: true,
        required: true,
        custom: true,
      },
      {
        name: "deepLink",
        label: "Deep link:",
        type: "text",
        helpText: "Campaign deep link.",
        value: partnerAppCampaign.deepLink,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (partnerAppCampaign.deepLink = event.target.value),
        row: true,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "Reward points amount.",
        value: partnerAppCampaign.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (partnerAppCampaign.reward = +event.target.value),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "maxDownloads",
        label: "Max downloads:",
        type: "number",
        helpText: "Maximum downloads count.",
        value: partnerAppCampaign.maximumDownloads,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (partnerAppCampaign.maximumDownloads = +event.target.value),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "ios",
        label: "Platform IOS:",
        helpText: "Show/hide campaign for IOS devices.",
        type: "checkbox",
        change: () => {
          if (partnerAppCampaign.platform) {
            partnerAppCampaign.platform.ios = !partnerAppCampaign.platform.ios;
          } else {
            partnerAppCampaign.platform = { ios: true, android: false };
          }
        },
        value: partnerAppCampaign.platform?.ios || false,
      },
      {
        name: "android",
        label: "Platform Android:",
        helpText: "Show/hide campaign for Android devices.",
        type: "checkbox",
        change: () => {
          if (partnerAppCampaign.platform) {
            partnerAppCampaign.platform.android = !partnerAppCampaign.platform.android;
          } else {
            partnerAppCampaign.platform = { ios: false, android: true };
          }
        },
        value: partnerAppCampaign.platform?.android || false,
      },

      {
        name: "appLogo",
        label: "App Logo:",
        helpText: "Campaign logo.",
        type: "image-cropper",
        imageType: ImageType.partner_app_logo,
        value: partnerAppCampaign.appLogoUrl,
        change: (imageUrl: string) => (partnerAppCampaign.appLogoUrl = imageUrl),
        row: true,
        custom: true,
      },
      {
        name: "appImage",
        label: "App image:",
        helpText: "Campaign image.",
        type: "image-cropper",
        imageType: ImageType.partner_app_image,
        value: partnerAppCampaign.appImageUrl,
        change: (imageUrl: string) => (partnerAppCampaign.appImageUrl = imageUrl),
        row: true,
        custom: true,
      },
    ],
    onSubmit: onSubmit,
    submitText: "Save",
    name: "edit-app-campaign-link",
  };

  return <CreateEditPage loading={isFetching} page={page} form={form} />;
};
