import { formatISO } from "date-fns";

import { BonusOfferInput, OfferInput, OfferModel, OfferRelation, OfferStatus } from "~/api/model";
import { BonusOfferEdit } from "~/pages/Offers/offerPart/BonusOfferForm";
import { OfferEdit } from "~/pages/Offers/offerPart/OfferForm";
import { OfferWithCategoriesAndTags } from "~/providers/marketplace";

export function getPublishedOfferCountsFromIds(listOfIds: string[], rewards: OfferModel[]): number {
  const published = rewards.filter((reward) => reward.status === OfferStatus.Published && reward._id && listOfIds.includes(reward._id));
  return published.length;
}

export function prepareOfferDuplication(offer: OfferWithCategoriesAndTags): OfferEdit {
  return {
    price: offer.price,
    subTitle: `${offer.subTitle} (Copy)`,
    companyDescription: offer.companyDescription || "",
    shortDescription: offer.shortDescription,
    url: offer.url || "",
    country: offer.country,
    categories: offer.categoryIds,
    tags: offer.tagIds,
    steplerOnly: offer.steplerOnly || false,
    containsSubscription: offer.containsSubscription || false,
    titleV2: offer.titleV2,
    longDescriptionV2: offer.longDescriptionV2,
    amount: offer.amount,
    order: undefined,
    purchaseCount: offer.purchaseCount || undefined,
    publishDate: offer.publishDate ? new Date(offer.publishDate) : new Date(),
    unPublishDate: offer.unPublishDate ? new Date(offer.unPublishDate) : new Date(),
    qty: offer.terms?.qty || "",
    shipping: offer.terms?.shipping || "",
    valid: offer.terms?.valid || "",
    other: offer.terms?.other || "",
    value: offer.terms?.value || "",
    ios: offer.platform.ios || true,
    android: offer.platform.android || true,
    status: OfferStatus.Unpublished,
    hideInList: offer.hideInList || false,
    relation: offer.relation || OfferRelation.Partner_offer,
    logoUrl: offer.logoUrl || "",
    thumbnailUrl: offer.thumbnailUrl,
    bannerUrl1: offer.bannerUrls[0] || null,
    bannerUrl2: offer.bannerUrls[1] || null,
    bannerUrl3: offer.bannerUrls[2] || null,
  };
}

export function prepareOfferUpdate(offer: OfferWithCategoriesAndTags): OfferInput {
  return {
    amount: offer.amount,
    android: offer.platform.android || false,
    bannerUrls: offer.bannerUrls,
    categories: offer.categoryIds,
    companyDescription: offer.companyDescription || "",
    containsSubscription: offer.containsSubscription || false,
    country: offer.country,
    hideInList: offer.hideInList || false,
    ios: offer.platform.ios || false,
    logoUrl: offer.logoUrl || "",
    longDescriptionV2: offer.longDescriptionV2,
    other: offer.terms?.other || "",
    price: offer.price,
    publishDate: offer.publishDate ? offer.publishDate : formatISO(new Date()),
    qty: offer.terms?.qty || "",
    relation: offer.relation || OfferRelation.Partner_offer,
    shipping: offer.terms?.shipping || "",
    shortDescription: offer.shortDescription,
    steplerOnly: offer.steplerOnly || false,
    subTitle: offer.subTitle || "",
    tags: offer.tagIds,
    thumbnailUrl: offer.thumbnailUrl || "",
    titleV2: offer.titleV2 || "",
    unPublishDate: offer.unPublishDate ? offer.unPublishDate : formatISO(new Date()),
    url: offer.url || "",
    valid: offer.terms?.valid || "",
    value: offer.terms?.value || "",
    order: offer.order,
    purchaseCount: offer.purchaseCount,
    status: offer.status,
  };
}

export function prepareBonusOfferDuplication(offer: OfferWithCategoriesAndTags): BonusOfferEdit {
  return {
    price: offer.price,
    subTitle: `${offer.subTitle} (Copy)`,
    companyDescription: offer.companyDescription || "",
    shortDescription: offer.shortDescription,
    url: offer.url || "",
    country: offer.country,
    categories: offer.categoryIds,
    tags: offer.tagIds,
    transactionTitle: offer.bonus?.transactionTitle || "",
    titleV2: offer.titleV2,
    longDescriptionV2: offer.longDescriptionV2,
    order: undefined,
    publishDate: offer.publishDate ? new Date(offer.publishDate) : new Date(),
    unPublishDate: offer.unPublishDate ? new Date(offer.unPublishDate) : new Date(),
    ios: offer.platform.ios || true,
    android: offer.platform.android || true,
    status: OfferStatus.Unpublished,
    hideInList: offer.hideInList || false,
    logoUrl: offer.logoUrl || "",
    thumbnailUrl: offer.thumbnailUrl,
    bannerUrl1: offer.bannerUrls[0] || null,
    bannerUrl2: offer.bannerUrls[1] || null,
    bannerUrl3: offer.bannerUrls[2] || null,
  };
}

export function prepareBonusOfferUpdate(offer: OfferWithCategoriesAndTags): BonusOfferInput {
  return {
    android: offer.platform.android || false,
    bannerUrls: offer.bannerUrls,
    categories: offer.categoryIds,
    companyDescription: offer.companyDescription || "",
    country: offer.country,
    hideInList: offer.hideInList || false,
    ios: offer.platform.ios || false,
    logoUrl: offer.logoUrl || "",
    longDescriptionV2: offer.longDescriptionV2,
    price: offer.price,
    publishDate: offer.publishDate ? offer.publishDate : formatISO(new Date()),
    shortDescription: offer.shortDescription,
    subTitle: offer.subTitle || "",
    tags: offer.tagIds,
    thumbnailUrl: offer.thumbnailUrl || "",
    titleV2: offer.titleV2 || "",
    unPublishDate: offer.unPublishDate ? offer.unPublishDate : formatISO(new Date()),
    url: offer.url || "",
    order: offer.order,
    status: offer.status,
    transactionTitle: offer.bonus?.transactionTitle || "",
  };
}
