import { FormEventHandler } from "react";
import clsx from "clsx";

import Button from "../button/Button";

import { FormField } from "./FormField";
import { getTypedField } from "./helpers";
import { Field } from "./types";

import "./form.scss";

type Props = {
  name: string;
  fields: Field[];
  onSubmit: FormEventHandler;
  submitText: string;
  vertical?: boolean;
  children?: any;
  showStayOnPage?: boolean;
  readonly?: boolean;
  loading?: boolean;
};

export const Form: React.FC<Props> = ({
  name,
  submitText,
  onSubmit,
  vertical,
  fields,
  showStayOnPage,
  children,
  readonly,
  loading = false,
}: Props) => {
  const classes = clsx({
    form: true,
    "form--vertical": vertical,
  });

  return (
    <form className={classes} onSubmit={onSubmit}>
      <div className="form__fields">
        {fields.map((field, index) => {
          if (readonly) {
            return (
              <div key={`${name}-${index}-readonly`}>
                {field.label}: {field.value || "-"}
              </div>
            );
          }
          return (
            <FormField
              key={`${name}-${index}`}
              label={field.label}
              required={field.required}
              name={field.name}
              helpText={field.helpText}
              big={field.big}
              row={field.row}
              disabled={field.disabled}
              custom={field.custom}
              triggered={field.triggered}
            >
              {getTypedField(field)}
            </FormField>
          );
        })}
      </div>
      {!readonly && (
        <div className="form__actions">
          {children}
          {showStayOnPage && (
            <Button disabled={loading} name="submit-stay" type="submit">
              {submitText} and stay on page
            </Button>
          )}
          <Button disabled={loading} name="submit" type="submit">
            {loading ? "Sending..." : submitText}
          </Button>
        </div>
      )}
    </form>
  );
};
