import * as React from "react";
import { useEffect } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Button } from "@/ui/button";
import { Calendar } from "@/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";

import { cn } from "~/helpers";

export const DatePicker = React.forwardRef<
  any,
  {
    defaultValue?: Date;
    onChange: (date: Date | undefined) => void;
    showHourPicker?: boolean;
  }
>(({ defaultValue, onChange, showHourPicker }, ref) => {
  const [date, setDate] = React.useState<Date | undefined>(defaultValue);
  const [hour, setHour] = React.useState<string | undefined>(defaultValue ? defaultValue.getHours().toString() : undefined);

  const handleDateChange = (date: Date | undefined) => {
    setDate(date);
  };

  const handleHourChange = (hour: string) => {
    if (date) {
      const localDate = new Date(date);
      localDate.setHours(parseInt(hour), 0, 0, 0);
      setDate(localDate);
    }
  };

  const getHour = () => {
    if (!showHourPicker || !date || !hour) {
      return "";
    }

    return `at ${hour}:00`;
  };

  useEffect(() => {
    onChange(date);

    if (date) {
      setHour(date.getHours().toString());
    } else {
      setHour(undefined);
    }
  }, [date]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={"input"} size={"input"} className={cn(!date && "text-muted-foreground")} ref={ref}>
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? `${format(date, "yyyy-MM-dd")} ${getHour()}`.trim() : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar mode="single" selected={date} onSelect={handleDateChange} />
        {showHourPicker && (
          <Select value={hour} onValueChange={handleHourChange}>
            <SelectTrigger>
              <SelectValue placeholder="Pick the time" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="0">0:00</SelectItem>
              <SelectItem value="1">1:00</SelectItem>
              <SelectItem value="2">2:00</SelectItem>
              <SelectItem value="3">3:00</SelectItem>
              <SelectItem value="4">4:00</SelectItem>
              <SelectItem value="5">5:00</SelectItem>
              <SelectItem value="6">6:00</SelectItem>
              <SelectItem value="7">7:00</SelectItem>
              <SelectItem value="8">8:00</SelectItem>
              <SelectItem value="9">9:00</SelectItem>
              <SelectItem value="10">10:00</SelectItem>
              <SelectItem value="11">11:00</SelectItem>
              <SelectItem value="12">12:00</SelectItem>
              <SelectItem value="13">13:00</SelectItem>
              <SelectItem value="14">14:00</SelectItem>
              <SelectItem value="15">15:00</SelectItem>
              <SelectItem value="16">16:00</SelectItem>
              <SelectItem value="17">17:00</SelectItem>
              <SelectItem value="18">18:00</SelectItem>
              <SelectItem value="19">19:00</SelectItem>
              <SelectItem value="20">20:00</SelectItem>
              <SelectItem value="21">21:00</SelectItem>
              <SelectItem value="22">22:00</SelectItem>
              <SelectItem value="23">23:00</SelectItem>
            </SelectContent>
          </Select>
        )}
      </PopoverContent>
    </Popover>
  );
});
