import * as React from "react";
import { useState } from "react";
import { jwtDecode } from "jwt-decode";

import { AXIOS_INSTANCE } from "~/api/base";
import SplashPage from "~/pages/Splash/Splash";
import { User } from "~/types/user";

export interface AuthContext {
  isAuthenticated: boolean;
  setAuth: (data: any) => Promise<void>;
  logout: () => Promise<void>;
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  roles: string[];
}

type StoredData = {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  roles: string[];
};

const AuthContext = React.createContext<AuthContext | null>(null);

const key = "auth";

function getStoredData() {
  const storedDate: string | null = localStorage.getItem(key);
  if (storedDate) {
    return JSON.parse(storedDate);
  }
  return {
    accessToken: null,
    refreshToken: null,
    user: null,
    roles: [],
  } as StoredData;
}

function setStoredData(data: StoredData | null) {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.removeItem(key);
  }
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const storedData = getStoredData();
  const [authData, setAuthData] = useState<StoredData>(storedData);
  const isAuthenticated = !!authData.accessToken;
  const [loggingOut, setLoggingOut] = useState(false);

  const logout = React.useCallback(async () => {
    setLoggingOut(true);
    AXIOS_INSTANCE.defaults.headers.Authorization = null;
    AXIOS_INSTANCE.defaults.headers.userid = null;

    setStoredData(null);
    setAuthData({
      accessToken: null,
      refreshToken: null,
      user: null,
      roles: [],
    });

    setTimeout(() => {
      setLoggingOut(false);
    }, 1000);
  }, []);

  const setAuth = React.useCallback(async (data: StoredData) => {
    const { permissions }: any = jwtDecode(data.accessToken as string);

    data.roles = permissions;
    AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
    AXIOS_INSTANCE.defaults.headers.userid = data.user?.user_id || null;
    setStoredData(data);
    setAuthData(data);
  }, []);

  React.useEffect(() => {
    setAuthData(storedData);
  }, []);

  if (storedData) {
    AXIOS_INSTANCE.defaults.headers.Authorization = `Bearer ${storedData.accessToken}`;
    AXIOS_INSTANCE.defaults.headers.userid = storedData.user?.user_id || null;
  }

  if (loggingOut) {
    return <SplashPage />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        setAuth: setAuth,
        logout: logout,
        user: authData.user,
        accessToken: authData.accessToken,
        refreshToken: authData.refreshToken,
        roles: authData.roles || [],
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
