import MarkdownPreview from "@uiw/react-markdown-preview/nohighlight";

import { Icon } from "@/icon/icon";
import { Button } from "@/ui/button";
import { Card, CardContent, CardHeader } from "@/ui/card";
import { Carousel, CarouselContent, CarouselItem } from "@/ui/carousel";

export function OfferPreview({ formData }: { formData: any }) {
  return (
    <div className="grid grid-cols-1 items-start gap-y-4">
      <Card>
        <CardHeader className="relative p-4 text-center font-medium uppercase">
          <div className="top-center absolute left-4 flex gap-2 text-sm">
            <Icon icon="ChevronLeft" /> Back
          </div>
          {formData.titleV2 || "Company name"}
        </CardHeader>
        <CardContent className="flex flex-col gap-6 p-0">
          <Carousel
            opts={{
              loop: true,
            }}
          >
            <CarouselContent>
              <CarouselItem>
                {formData.bannerUrl1 && <img className="aspect-square w-full" src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.bannerUrl1}`} />}
              </CarouselItem>
              <CarouselItem>
                {formData.bannerUrl2 && <img className="aspect-square w-full" src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.bannerUrl2}`} />}
              </CarouselItem>
              <CarouselItem>
                {formData.bannerUrl3 && <img className="aspect-square w-full" src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.bannerUrl3}`} />}
              </CarouselItem>
            </CarouselContent>
          </Carousel>
          <div></div>
          <div className="flex flex-col gap-6 p-4">
            <h2>{formData.shortDescription || "Title"}</h2>
            <div className="flex gap-5">
              <div className="flex flex-grow flex-col gap-1">
                <div className="font-medium">Many left</div>
                <div className="text-sm text-foregroundLowlight">Status</div>
              </div>
              <div className="flex flex-col items-center gap-1 text-sm text-foregroundLowlight">
                <Icon className="h-6 w-6 text-foreground" icon="Bookmark" />
                Favorite
              </div>
              <div className="flex flex-col items-center gap-1 text-sm text-foregroundLowlight">
                <Icon className="h-6 w-6 text-foreground" icon="Share" />
                Share
              </div>
            </div>
            <hr className="border border-foregroundLowlight"></hr>
            {(formData.qty || formData.shipping || formData.valid || formData.value || formData.other) && (
              <>
                <div>
                  <div>Terms</div>
                  <table>
                    <tbody>
                      {formData.qty && (
                        <tr>
                          <td>Quantity:</td>
                          <td>{formData.qty}</td>
                        </tr>
                      )}
                      {formData.shipping && (
                        <tr>
                          <td>Shipping:</td>
                          <td>{formData.shipping}</td>
                        </tr>
                      )}
                      {formData.valid && (
                        <tr>
                          <td>Valid:</td>
                          <td>{formData.valid}</td>
                        </tr>
                      )}
                      {formData.value && (
                        <tr>
                          <td>Value:</td>
                          <td>{formData.value}</td>
                        </tr>
                      )}
                      {formData.other && (
                        <tr>
                          <td>Other:</td>
                          <td>{formData.other}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <hr className="border border-foregroundLowlight"></hr>
              </>
            )}
            {formData.steplerOnly && (
              <div className="flex items-center gap-2 font-medium">
                <Icon icon="SteplerLogo" />
                Only at Stepler
              </div>
            )}
            <MarkdownPreview className="text-foreground" source={formData.longDescriptionV2} />
            <div className="flex flex-col gap-6 bg-accent p-4 text-foreground">
              <div className="flex justify-center">
                <img className="h-14 w-14 rounded-full" src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${formData.logoUrl}`} />
              </div>
              <MarkdownPreview className="text-foreground" source={formData.companyDescription} />
              <Button variant={"outline"}>Visit Website</Button>
            </div>
            <Button className="flex gap-1">
              Claim for <Icon icon="Coin" />
              {formData.price || "Price"}
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
