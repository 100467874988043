import { useState } from "react";

import { Icon } from "@/icon/icon";

import { Route } from "../../routes_old";

import { RoutedLink } from "./RoutedLink";

import { useAuth } from "~/providers/auth";

type Props = {
  menuOpened: boolean;
  route: Route;
};

export const Nestedroute = ({ menuOpened, route }: Props) => {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const { roles } = useAuth();

  if (!roles.includes(route.role)) return null;

  return (
    <>
      <div className={`group hover:relative bg-sidebar ${menuOpened ? "" : "hover:w-72"}`} onClick={() => setSubMenuOpened(!subMenuOpened)}>
        <div
          className={`flex ${menuOpened ? "gap-2" : ""} items-center border-l-8 border-transparent hover:relative hover:border-primary bg-sidebar`}
        >
          <div className="px-4 py-5">
            <Icon icon={route.icon} />
          </div>
          <div className={`bg-sidebar ${menuOpened ? "py-4 pr-4" : "p-4 hidden group-hover:block z-10"} flex-grow text-sm`}>{route.name}</div>
          {menuOpened && <div className="p-4">{menuOpened && (subMenuOpened ? <Icon icon="ArrowDown" /> : <Icon icon="ArrowRight" />)}</div>}
        </div>
        {/* position: absolute; width: calc(100% - 55px); display: none; top: 100%; left: 55px; background-color: var(--navbar-hover-color); */}
        {!menuOpened && (
          <section className="absolute hidden bg-sidebar top-full left-16 group-hover:block w-56 z-10">
            {route.nested?.map((route, i) => <RoutedLink key={i} route={route as Route} menuOpened={menuOpened} />)}
          </section>
        )}
      </div>
      {menuOpened && subMenuOpened && (
        <div className="nested-nav-item_dropdown">
          {route.nested?.map((route, i) => <RoutedLink key={i} route={route as Route} menuOpened={menuOpened} />)}
        </div>
      )}
    </>
  );
};
