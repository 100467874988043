import { memo } from "react";
import clsx from "clsx";

import Icon from "../icon/Icon";
import Loader from "../loader/Loader";

import "./button.scss";

interface OwnProps {
  styleType?: "success" | "danger" | "secondary";
  loading?: boolean;
  size?: "small" | "medium" | "large";
  block?: boolean;
  paddingless?: boolean;
  icon?: string;
}

type Props = OwnProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = memo(({ children, styleType, loading, icon, block, paddingless, disabled, size = "medium", ...buttonProps }: Props) => {
  const classes = clsx({
    button: true,
    "button--secondary border border-input bg-background hover:bg-accent hover:text-accent-foreground": styleType === "secondary",
    "button--success": styleType === "success",
    "button--danger": styleType === "danger",
    "button--small": size === "small",
    "button--large": size === "large",
    "button--icon": icon,
    "button--block": block,
    "button--paddingless": paddingless,
  });

  return (
    <button disabled={disabled ? disabled : undefined} className={classes} type={"button"} {...buttonProps}>
      {loading && <Loader page={false} />}
      {!loading && children}
      {icon && <Icon icon={icon} />}
    </button>
  );
});

export default Button;
