import { memo } from "react";
import { FaSpinner } from "react-icons/fa6";

import "./loader.scss";

type Props = {
  page: boolean;
};

const Loader = memo(({ page }: Props) => (
  <>
    {page && (
      <section className="loader_wrapper">
        <div className="loader" />
      </section>
    )}
    {!page && <FaSpinner className="loader" />}
  </>
));

export default Loader;
