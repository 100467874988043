import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { isAfter, isBefore } from "date-fns";
import { toast } from "sonner";
import { z } from "zod";

import {
  useGetAllInviteCampaignsV1InviteCampaignsGet,
  useUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch,
} from "~/api/invite-campaigns/invite-campaigns.gen";
import { CampaignStatus } from "~/api/model";
import { getCountryOptions } from "~/helpers/country-list";
import formatDate from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, TableConfig } from "~/oldComponents/layout/types";
import { Select } from "~/oldComponents/select/Select";

export const Route = createFileRoute("/_auth/invite-campaigns/")({
  validateSearch: z.object({
    country: z.string().default(""),
    status: z.enum([CampaignStatus.Active, CampaignStatus.Inactive, CampaignStatus.Deleted]).default(CampaignStatus.Active),
  }),
  component: () => <InviteCampaignsPage />,
});

function InviteCampaignsPage() {
  const search = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const { data, isFetching, refetch } = useGetAllInviteCampaignsV1InviteCampaignsGet({
    country: search.country,
    status: search.status,
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
  };

  const updateCampaignMutation = useUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Invite campaign was successfully updated!");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const onStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, inviteCampaign: any) => {
    e.preventDefault();

    await updateCampaignMutation.mutateAsync({
      inviteCampaignId: inviteCampaign._id,
      data: {
        ...inviteCampaign.data,
        status: e.target.value,
      },
    });
    refetch();
  };

  const page: PageConfig = {
    title: "Invite campaigns",
    description:
      "Create new or edit current invite campaigns\n\nStatus Active: The campaign can be shown to the users if current date is between start and end date\nStatus Inactive: The campaign will not be shown to the users",
    addLink: "/invite-campaigns/create",
    tableUpload: {
      fileName: "invite-campaigns",
      data: data,
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Live (shown to users)",
        format: (item: any) => {
          const now = new Date();
          const startDate = new Date(item.startDate);
          const endDate = new Date(item.endDate);
          if (isAfter(now, startDate) && isBefore(now, endDate)) {
            return "Yes";
          }
          return "No";
        },
      },
      {
        headerTitle: "Title",
        format: (item: any) => item.title,
      },
      {
        headerTitle: "Country",
        format: (item: any) => item.country,
      },
      {
        headerTitle: "Start date",
        format: (item: any) => formatDate(item.startDate),
      },
      {
        headerTitle: "End date",
        format: (item: any) => formatDate(item.endDate),
      },
      {
        headerTitle: "Invites count",
        format: (item: any) => item.invitesCount,
      },
      {
        headerTitle: "Campaign limit",
        format: (item: any) => item.campaignLimit || "-",
      },
      {
        headerTitle: "Status",
        actionRow: true,
        format: (item: any) => (
          <Select
            selectedValue={item.status}
            optionList={[
              {
                name: "Active",
                value: "Active",
              },
              {
                name: "Inactive",
                value: "Inactive",
              },
              {
                name: "Deleted",
                value: "Deleted",
              },
            ]}
            onChange={(e) => onStatusChange(e, item)}
          />
        ),
      },
    ],
    objects: data,
    link: (item: any) => navigate({ to: "/invite-campaigns/$campaignId", params: { campaignId: item._id } }),
    emptyMessage: "There are no invite campaigns in the system yet.",
  };

  const searchBar: FormConfig = {
    name: "partner-app-searchbar",
    fields: [
      {
        name: "country",
        label: "Country:",
        helpText: "",
        type: "search-select",
        change: (data: string) => navigate({ search: { ...search, country: data } }),
        value: search.country,
        options: getCountryOptions(),
        empty: "All",
      },
      {
        name: "status",
        label: "Status:",
        helpText: "",
        type: "select",
        change: (e: React.ChangeEvent<HTMLSelectElement>) => navigate({ search: { ...search, status: e.target.value as CampaignStatus } }),
        value: search.status,
        options: [
          {
            name: "Active",
            value: CampaignStatus.Active,
          },
          {
            name: "Inactive",
            value: CampaignStatus.Inactive,
          },
          {
            name: "Deleted",
            value: CampaignStatus.Deleted,
          },
        ],
      },
    ],
    onSubmit: onSubmit,
    submitText: "Search",
    vertical: true,
  };

  return <ListPage form={searchBar} loading={isFetching} page={page} table={table} />;
}
