/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  BulkAddTagsToOffersInput,
  BulkChangeOffersStatusInput,
  CategoryMarketModel,
  CategoryRewardOrder,
  CreateTagInput,
  CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams,
  CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem,
  CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams,
  CreatedUpdatedDeleted,
  Error400Response,
  GetCategoriesV1MarketplaceCategoriesGetParams,
  GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams,
  GetMarketplaceDataV1MarketplaceGetParams,
  GetRecommendedV1MarketplaceRecommendedGetParams,
  GetTagsV1MarketplaceTagsGetParams,
  HTTPValidationError,
  HighlightedCollectionModel,
  MarketplaceResponseModel,
  RecommendedModel,
  TagModel,
  TagRewardOrder
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get all marketplace data (offers, categories, tags, highlighted collections and recommended
 * @summary Get Marketplace Data
 */
export const getMarketplaceDataV1MarketplaceGet = (
    params: GetMarketplaceDataV1MarketplaceGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<MarketplaceResponseModel>(
      {url: `/v1/marketplace/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetMarketplaceDataV1MarketplaceGetQueryKey = (params: GetMarketplaceDataV1MarketplaceGetParams,) => {
    return [`/v1/marketplace/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetMarketplaceDataV1MarketplaceGetQueryOptions = <TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetMarketplaceDataV1MarketplaceGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>> = ({ signal }) => getMarketplaceDataV1MarketplaceGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetMarketplaceDataV1MarketplaceGetQueryResult = NonNullable<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>>
export type GetMarketplaceDataV1MarketplaceGetQueryError = ErrorType<HTTPValidationError>


export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Marketplace Data
 */

export function useGetMarketplaceDataV1MarketplaceGet<TData = Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetMarketplaceDataV1MarketplaceGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMarketplaceDataV1MarketplaceGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetMarketplaceDataV1MarketplaceGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Bulk Add Tags to Offers
 * @summary Add Tags To Offers
 */
export const addTagsToOffersV1MarketplaceBulkOffersTagsPost = (
    bulkAddTagsToOffersInput: BodyType<BulkAddTagsToOffersInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/marketplace/bulk/offers/tags/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: bulkAddTagsToOffersInput
    },
      options);
    }
  


export const getAddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, {data: BodyType<BulkAddTagsToOffersInput>}> = (props) => {
          const {data} = props ?? {};

          return  addTagsToOffersV1MarketplaceBulkOffersTagsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationResult = NonNullable<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>>
    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationBody = BodyType<BulkAddTagsToOffersInput>
    export type AddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Add Tags To Offers
 */
export const useAddTagsToOffersV1MarketplaceBulkOffersTagsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>, TError,{data: BodyType<BulkAddTagsToOffersInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof addTagsToOffersV1MarketplaceBulkOffersTagsPost>>,
        TError,
        {data: BodyType<BulkAddTagsToOffersInput>},
        TContext
      > => {

      const mutationOptions = getAddTagsToOffersV1MarketplaceBulkOffersTagsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk change offers status
 * @summary Change Offers Status
 */
export const changeOffersStatusV1MarketplaceBulkOffersStatusPut = (
    bulkChangeOffersStatusInput: BodyType<BulkChangeOffersStatusInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/marketplace/bulk/offers/status/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkChangeOffersStatusInput
    },
      options);
    }
  


export const getChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, {data: BodyType<BulkChangeOffersStatusInput>}> = (props) => {
          const {data} = props ?? {};

          return  changeOffersStatusV1MarketplaceBulkOffersStatusPut(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationResult = NonNullable<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>>
    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationBody = BodyType<BulkChangeOffersStatusInput>
    export type ChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Change Offers Status
 */
export const useChangeOffersStatusV1MarketplaceBulkOffersStatusPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>, TError,{data: BodyType<BulkChangeOffersStatusInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof changeOffersStatusV1MarketplaceBulkOffersStatusPut>>,
        TError,
        {data: BodyType<BulkChangeOffersStatusInput>},
        TContext
      > => {

      const mutationOptions = getChangeOffersStatusV1MarketplaceBulkOffersStatusPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create Tag
 * @summary Create Tag
 */
export const createTagV1MarketplaceTagsPost = (
    createTagInput: BodyType<CreateTagInput>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TagModel>(
      {url: `/v1/marketplace/tags/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createTagInput
    },
      options);
    }
  


export const getCreateTagV1MarketplaceTagsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, {data: BodyType<CreateTagInput>}> = (props) => {
          const {data} = props ?? {};

          return  createTagV1MarketplaceTagsPost(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateTagV1MarketplaceTagsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>>
    export type CreateTagV1MarketplaceTagsPostMutationBody = BodyType<CreateTagInput>
    export type CreateTagV1MarketplaceTagsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Tag
 */
export const useCreateTagV1MarketplaceTagsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>, TError,{data: BodyType<CreateTagInput>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createTagV1MarketplaceTagsPost>>,
        TError,
        {data: BodyType<CreateTagInput>},
        TContext
      > => {

      const mutationOptions = getCreateTagV1MarketplaceTagsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get Tags
 * @summary Get Tags
 */
export const getTagsV1MarketplaceTagsGet = (
    params: GetTagsV1MarketplaceTagsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<TagModel[]>(
      {url: `/v1/marketplace/tags/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetTagsV1MarketplaceTagsGetQueryKey = (params: GetTagsV1MarketplaceTagsGetParams,) => {
    return [`/v1/marketplace/tags/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetTagsV1MarketplaceTagsGetQueryOptions = <TData = Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError = ErrorType<HTTPValidationError>>(params: GetTagsV1MarketplaceTagsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTagsV1MarketplaceTagsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>> = ({ signal }) => getTagsV1MarketplaceTagsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTagsV1MarketplaceTagsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>>
export type GetTagsV1MarketplaceTagsGetQueryError = ErrorType<HTTPValidationError>


export function useGetTagsV1MarketplaceTagsGet<TData = Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTagsV1MarketplaceTagsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTagsV1MarketplaceTagsGet<TData = Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTagsV1MarketplaceTagsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetTagsV1MarketplaceTagsGet<TData = Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTagsV1MarketplaceTagsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Tags
 */

export function useGetTagsV1MarketplaceTagsGet<TData = Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetTagsV1MarketplaceTagsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTagsV1MarketplaceTagsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetTagsV1MarketplaceTagsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update Tags
 * @summary Update Tag
 */
export const updateTagV1MarketplaceTagsTagIdPut = (
    tagId: string,
    tagRewardOrder: BodyType<TagRewardOrder>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<TagModel>(
      {url: `/v1/marketplace/tags/${tagId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: tagRewardOrder
    },
      options);
    }
  


export const getUpdateTagV1MarketplaceTagsTagIdPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<TagRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<TagRewardOrder>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, {tagId: string;data: BodyType<TagRewardOrder>}> = (props) => {
          const {tagId,data} = props ?? {};

          return  updateTagV1MarketplaceTagsTagIdPut(tagId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateTagV1MarketplaceTagsTagIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>>
    export type UpdateTagV1MarketplaceTagsTagIdPutMutationBody = BodyType<TagRewardOrder>
    export type UpdateTagV1MarketplaceTagsTagIdPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Tag
 */
export const useUpdateTagV1MarketplaceTagsTagIdPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>, TError,{tagId: string;data: BodyType<TagRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateTagV1MarketplaceTagsTagIdPut>>,
        TError,
        {tagId: string;data: BodyType<TagRewardOrder>},
        TContext
      > => {

      const mutationOptions = getUpdateTagV1MarketplaceTagsTagIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete Tags
 * @summary Delete Tag
 */
export const deleteTagV1MarketplaceTagsTagIdDelete = (
    tagId: string,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<void>(
      {url: `/v1/marketplace/tags/${tagId}/`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTagV1MarketplaceTagsTagIdDeleteMutationOptions = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, {tagId: string}> = (props) => {
          const {tagId} = props ?? {};

          return  deleteTagV1MarketplaceTagsTagIdDelete(tagId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteTagV1MarketplaceTagsTagIdDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>>
    
    export type DeleteTagV1MarketplaceTagsTagIdDeleteMutationError = ErrorType<Error400Response | HTTPValidationError>

    /**
 * @summary Delete Tag
 */
export const useDeleteTagV1MarketplaceTagsTagIdDelete = <TError = ErrorType<Error400Response | HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>, TError,{tagId: string}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteTagV1MarketplaceTagsTagIdDelete>>,
        TError,
        {tagId: string},
        TContext
      > => {

      const mutationOptions = getDeleteTagV1MarketplaceTagsTagIdDeleteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get categories
 * @summary Get Categories
 */
export const getCategoriesV1MarketplaceCategoriesGet = (
    params: GetCategoriesV1MarketplaceCategoriesGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<CategoryMarketModel[]>(
      {url: `/v1/marketplace/categories/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetCategoriesV1MarketplaceCategoriesGetQueryKey = (params: GetCategoriesV1MarketplaceCategoriesGetParams,) => {
    return [`/v1/marketplace/categories/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetCategoriesV1MarketplaceCategoriesGetQueryOptions = <TData = Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError = ErrorType<HTTPValidationError>>(params: GetCategoriesV1MarketplaceCategoriesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCategoriesV1MarketplaceCategoriesGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>> = ({ signal }) => getCategoriesV1MarketplaceCategoriesGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCategoriesV1MarketplaceCategoriesGetQueryResult = NonNullable<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>>
export type GetCategoriesV1MarketplaceCategoriesGetQueryError = ErrorType<HTTPValidationError>


export function useGetCategoriesV1MarketplaceCategoriesGet<TData = Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCategoriesV1MarketplaceCategoriesGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategoriesV1MarketplaceCategoriesGet<TData = Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCategoriesV1MarketplaceCategoriesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCategoriesV1MarketplaceCategoriesGet<TData = Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCategoriesV1MarketplaceCategoriesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Categories
 */

export function useGetCategoriesV1MarketplaceCategoriesGet<TData = Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetCategoriesV1MarketplaceCategoriesGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCategoriesV1MarketplaceCategoriesGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCategoriesV1MarketplaceCategoriesGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Re-order the categories by providing a list of Ids in the order that they need to be stored.
 * @summary Reorder Categories
 */
export const reorderCategoriesV1MarketplaceCategoriesReorderPut = (
    reorderCategoriesV1MarketplaceCategoriesReorderPutBody: BodyType<string[]>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<unknown>(
      {url: `/v1/marketplace/categories/reorder/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reorderCategoriesV1MarketplaceCategoriesReorderPutBody
    },
      options);
    }
  


export const getReorderCategoriesV1MarketplaceCategoriesReorderPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, {data: BodyType<string[]>}> = (props) => {
          const {data} = props ?? {};

          return  reorderCategoriesV1MarketplaceCategoriesReorderPut(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationResult = NonNullable<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>>
    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationBody = BodyType<string[]>
    export type ReorderCategoriesV1MarketplaceCategoriesReorderPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Reorder Categories
 */
export const useReorderCategoriesV1MarketplaceCategoriesReorderPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>, TError,{data: BodyType<string[]>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof reorderCategoriesV1MarketplaceCategoriesReorderPut>>,
        TError,
        {data: BodyType<string[]>},
        TContext
      > => {

      const mutationOptions = getReorderCategoriesV1MarketplaceCategoriesReorderPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update category
 * @summary Update Category
 */
export const updateCategoryV1MarketplaceCategoriesCategoryIdPut = (
    categoryId: string,
    categoryRewardOrder: BodyType<CategoryRewardOrder>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CategoryMarketModel>(
      {url: `/v1/marketplace/categories/${categoryId}/`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: categoryRewardOrder
    },
      options);
    }
  


export const getUpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, {categoryId: string;data: BodyType<CategoryRewardOrder>}> = (props) => {
          const {categoryId,data} = props ?? {};

          return  updateCategoryV1MarketplaceCategoriesCategoryIdPut(categoryId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationResult = NonNullable<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>>
    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationBody = BodyType<CategoryRewardOrder>
    export type UpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update Category
 */
export const useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>, TError,{categoryId: string;data: BodyType<CategoryRewardOrder>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateCategoryV1MarketplaceCategoriesCategoryIdPut>>,
        TError,
        {categoryId: string;data: BodyType<CategoryRewardOrder>},
        TContext
      > => {

      const mutationOptions = getUpdateCategoryV1MarketplaceCategoriesCategoryIdPutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get highlighted collections
 * @summary Get Highlighted Collections
 */
export const getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet = (
    params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<HighlightedCollectionModel[]>(
      {url: `/v1/marketplace/highlighted_collections/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryKey = (params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams,) => {
    return [`/v1/marketplace/highlighted_collections/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryOptions = <TData = Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError = ErrorType<HTTPValidationError>>(params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>> = ({ signal }) => getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryResult = NonNullable<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>>
export type GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryError = ErrorType<HTTPValidationError>


export function useGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet<TData = Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet<TData = Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet<TData = Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Highlighted Collections
 */

export function useGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet<TData = Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getHighlightedCollectionsV1MarketplaceHighlightedCollectionsGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetHighlightedCollectionsV1MarketplaceHighlightedCollectionsGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create/Update and Delete highlighted collections. This call will take all highlighted collections that need to be created or updated. All collections that are not in the list will be deleted.
 * @summary Create Update Delete Highlighted Collection
 */
export const createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost = (
    highlightedCollectionModel: BodyType<HighlightedCollectionModel[]>,
    params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CreatedUpdatedDeleted>(
      {url: `/v1/marketplace/highlighted_collections/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: highlightedCollectionModel,
        params
    },
      options);
    }
  


export const getCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModel[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModel[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, {data: BodyType<HighlightedCollectionModel[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>>
    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationBody = BodyType<HighlightedCollectionModel[]>
    export type CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Update Delete Highlighted Collection
 */
export const useCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>, TError,{data: BodyType<HighlightedCollectionModel[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPost>>,
        TError,
        {data: BodyType<HighlightedCollectionModel[]>;params: CreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostParams},
        TContext
      > => {

      const mutationOptions = getCreateUpdateDeleteHighlightedCollectionV1MarketplaceHighlightedCollectionsPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get recommended items
 * @summary Get Recommended
 */
export const getRecommendedV1MarketplaceRecommendedGet = (
    params: GetRecommendedV1MarketplaceRecommendedGetParams,
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<RecommendedModel[]>(
      {url: `/v1/marketplace/recommended/`, method: 'GET',
        params, signal
    },
      options);
    }
  

export const getGetRecommendedV1MarketplaceRecommendedGetQueryKey = (params: GetRecommendedV1MarketplaceRecommendedGetParams,) => {
    return [`/v1/marketplace/recommended/`, ...(params ? [params]: [])] as const;
    }

    
export const getGetRecommendedV1MarketplaceRecommendedGetQueryOptions = <TData = Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError = ErrorType<HTTPValidationError>>(params: GetRecommendedV1MarketplaceRecommendedGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetRecommendedV1MarketplaceRecommendedGetQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>> = ({ signal }) => getRecommendedV1MarketplaceRecommendedGet(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetRecommendedV1MarketplaceRecommendedGetQueryResult = NonNullable<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>>
export type GetRecommendedV1MarketplaceRecommendedGetQueryError = ErrorType<HTTPValidationError>


export function useGetRecommendedV1MarketplaceRecommendedGet<TData = Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetRecommendedV1MarketplaceRecommendedGetParams, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRecommendedV1MarketplaceRecommendedGet<TData = Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetRecommendedV1MarketplaceRecommendedGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetRecommendedV1MarketplaceRecommendedGet<TData = Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetRecommendedV1MarketplaceRecommendedGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get Recommended
 */

export function useGetRecommendedV1MarketplaceRecommendedGet<TData = Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError = ErrorType<HTTPValidationError>>(
 params: GetRecommendedV1MarketplaceRecommendedGetParams, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecommendedV1MarketplaceRecommendedGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetRecommendedV1MarketplaceRecommendedGetQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create/Update and Delete recommended items. This call will take all recommended items that need to be created or updated. All recommended items that are not in the list will be deleted.
 * @summary Create Update Delete Recommended
 */
export const createUpdateDeleteRecommendedV1MarketplaceRecommendedPost = (
    createUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>,
    params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<CreatedUpdatedDeleted>(
      {url: `/v1/marketplace/recommended/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem,
        params
    },
      options);
    }
  


export const getCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, {data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createUpdateDeleteRecommendedV1MarketplaceRecommendedPost(data,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>>
    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationBody = BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>
    export type CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Create Update Delete Recommended
 */
export const useCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPost = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>, TError,{data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof createUpdateDeleteRecommendedV1MarketplaceRecommendedPost>>,
        TError,
        {data: BodyType<CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostBodyItem[]>;params: CreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostParams},
        TContext
      > => {

      const mutationOptions = getCreateUpdateDeleteRecommendedV1MarketplaceRecommendedPostMutationOptions(options);

      return useMutation(mutationOptions);
    }
    