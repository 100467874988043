/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type SourceType = typeof SourceType[keyof typeof SourceType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SourceType = {
  UserInvite: 'UserInvite',
  AcceptInvite: 'AcceptInvite',
  Order: 'Order',
  Steps: 'Steps',
  WatchingVideo: 'WatchingVideo',
  FacebookAttachment: 'FacebookAttachment',
  AppleAttachment: 'AppleAttachment',
  AdminPoints: 'AdminPoints',
  FiveKReward: 'FiveKReward',
  TenKReward: 'TenKReward',
  FifteenKReward: 'FifteenKReward',
  TwentyKReward: 'TwentyKReward',
  ThirtyKReward: 'ThirtyKReward',
  FourtyKReward: 'FourtyKReward',
  HundredKReward: 'HundredKReward',
  TwoAndAHalfThousandReward: 'TwoAndAHalfThousandReward',
  FiveThousandReward: 'FiveThousandReward',
  SevenAndAHalfThousandReward: 'SevenAndAHalfThousandReward',
  TwelveAndAHalfThousandReward: 'TwelveAndAHalfThousandReward',
  FacebookVisitReward: 'FacebookVisitReward',
  InstagramVisitReward: 'InstagramVisitReward',
  GoogleAttachment: 'GoogleAttachment',
  TikTokVisitReward: 'TikTokVisitReward',
  StepCampaignReward: 'StepCampaignReward',
  PartnersAppCampaignReward: 'PartnersAppCampaignReward',
  weekStreakReward: 'weekStreakReward',
  PointsLink: 'PointsLink',
  AutoReward: 'AutoReward',
  LeaderboardReward: 'LeaderboardReward',
  SurveyReward: 'SurveyReward',
  PlayToEarnBonus: 'PlayToEarnBonus',
  SurveyRankReached: 'SurveyRankReached',
  SurveyBonus: 'SurveyBonus',
  ProfileCompletion: 'ProfileCompletion',
  FiveTeenKReward: 'FiveTeenKReward',
} as const;
