/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type RecommendedType = typeof RecommendedType[keyof typeof RecommendedType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecommendedType = {
  Tag: 'Tag',
  Category: 'Category',
  Offer: 'Offer',
  Url: 'Url',
} as const;
