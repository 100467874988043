import { memo } from "react";

import Button from "../button/Button";

import { FormField } from "./FormField";
import { getTypedField } from "./helpers";
import { Field } from "./types";

import "./form.scss";

type Props = {
  name: string;
  fields: Field[];
  onSubmit: (formData: any) => void;
  loading?: boolean;
};

export const SearchForm: React.FC<Props> = memo(({ name, onSubmit, fields, loading = false }: Props) => {
  const formSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const form = document.getElementById(name) as HTMLFormElement;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());
    onSubmit(data);
  };

  return (
    <form className="form" id={name} onSubmit={formSubmit}>
      <div className="form__fields">
        {fields.map((field, index) => {
          return (
            <FormField
              key={`${name}-${index}`}
              label={field.label}
              required={field.required}
              name={field.name}
              helpText={field.helpText}
              big={field.big}
              row={field.row}
              disabled={field.disabled}
              custom={field.custom}
            >
              {getTypedField(field)}
            </FormField>
          );
        })}
      </div>
      <div className="form__actions">
        <Button disabled={loading} name="submit" type="submit">
          {loading ? "Searching..." : "Search"}
        </Button>
      </div>
    </form>
  );
});
