import { useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import Tab from "@/tab";
import { Card } from "@/ui/card";

import {
  getGetGeneralConfigV1GeneralConfigGetQueryOptions,
  useUpdateGeneralConfigV1GeneralConfigPatch,
} from "~/api/general-config/general-config.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { FormSet } from "~/oldComponents/form/FormSet";
import { Field } from "~/oldComponents/form/types";
import { useAuth } from "~/providers/auth";

export const Route = createFileRoute("/_auth/general-config/auto-claim")({
  loader: ({ context: { queryClient } }) => {
    return queryClient.ensureQueryData(getGetGeneralConfigV1GeneralConfigGetQueryOptions());
  },
  component: () => <GeneralConfigPage />,
});

function GeneralConfigPage() {
  const { roles } = useAuth();

  const { data: generalConfig } = useSuspenseQuery(getGetGeneralConfigV1GeneralConfigGetQueryOptions());
  const [autoRewards, setAutoRewards] = useState<any>(generalConfig.autoRewards);

  const updateGeneralConfigMutation = useUpdateGeneralConfigV1GeneralConfigPatch({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("Config was successfully updated!");
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await updateGeneralConfigMutation.mutateAsync({ data: { ...generalConfig, autoRewards: autoRewards } });
  };

  const templateFields: Field[] = [
    {
      name: "steps",
      label: "Steps amount",
      helpText: "The amount of steps that the user must have to be able to auto-claim the reward.",
      type: "number",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.dataset.index || "0");
        const local = [...autoRewards];
        if (!local[index]) local.push({ steps: 0, offerId: "", claimDate: "", oneSignalTag: "" });
        local[index].steps = parseInt(event.target.value);
        setAutoRewards(local);
      },
      value: undefined,
    },
    {
      name: "offerId",
      label: "Offer Id",
      helpText: "The id of the offer that will be claimed when the user reaches the steps amount.",
      type: "text",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.dataset.index || "0");
        const local = [...autoRewards];
        if (!local[index]) local.push({ steps: 0, offerId: "", claimDate: "", oneSignalTag: "" });
        local[index].offerId = event.target.value;
        setAutoRewards(local);
      },
      value: undefined,
    },
    {
      name: "claimDate",
      label: "Claim date",
      helpText: "The date that the offer can be auto-claimed. Format: YYYY-MM-DD",
      type: "text",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.dataset.index || "0");
        const local = [...autoRewards];
        if (!local[index]) local.push({ steps: 0, offerId: "", claimDate: "", oneSignalTag: "" });
        local[index].claimDate = event.target.value;
        setAutoRewards(local);
      },
      value: undefined,
    },
    {
      name: "oneSignalTag",
      label: "One signal tag",
      helpText: "The one signal tag that should be created when the user auto-claims the reward.",
      type: "text",
      change: (event: React.ChangeEvent<HTMLInputElement>) => {
        const index = parseInt(event.target.dataset.index || "0");
        const local = [...autoRewards];
        if (!local[index]) local.push({ steps: 0, offerId: "", claimDate: "", oneSignalTag: "" });
        local[index].oneSignalTag = event.target.value;
        setAutoRewards(local);
      },
      value: undefined,
    },
  ];

  const addRow = () => {
    if (!generalConfig) return;

    const local = [...(generalConfig.autoRewards || [])];
    local.push({ steps: 0, offerId: "", claimDate: "", oneSignalTag: "" });
    setAutoRewards(local);
  };

  const deleteRow = (index: number) => {
    if (!generalConfig) return;

    const local = [...(generalConfig.autoRewards || [])];
    local.splice(index, 1);
    setAutoRewards(local);
  };

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section className="flex items-start justify-between">
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Edit General Config</h2>
        </div>
      </section>
      <div>
        <div className="flex w-full gap-2">
          <Tab active={false} to={"/general-config"} title="Rewards" />
          <Tab active={false} to={"/general-config/offer"} title="Offer" />
          <Tab active={false} to={"/general-config/anti-cheat"} title="Anti Cheat" />
          <Tab active={true} to={"/general-config/auto-claim"} title="Auto claim" />
        </div>
        <Card className="bg-card px-4 py-10 text-card-foreground">
          <FormSet
            readonly={!roles.includes("Admin_Owner")}
            name="anti-cheat"
            initialData={autoRewards}
            onSubmit={onSubmit}
            submitText="Save auto claim config"
            templateFields={templateFields}
            addedRow={addRow}
            deleteRow={deleteRow}
          />
        </Card>
      </div>
    </section>
  );
}
