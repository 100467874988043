import { useEffect, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import {
  getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryOptions,
  useUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch,
} from "~/api/invite-campaigns/invite-campaigns.gen";
import { CampaignStatus, ImageType } from "~/api/model";
import { useGetOffersV1OffersGet } from "~/api/offers/offers.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { convertToUsableDate } from "~/helpers/date-formatting";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/invite-campaigns/$campaignId")({
  loader: ({ context: { queryClient }, params: { campaignId } }) => {
    return queryClient.ensureQueryData(getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryOptions(campaignId));
  },
  component: () => <UpdateInviteCampaignPage />,
});

function UpdateInviteCampaignPage() {
  const navigate = useNavigate();

  const campaignId = Route.useParams().campaignId;

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [country, setCountry] = useState<string>("");

  const { data: inviteCampaign, isFetching } = useSuspenseQuery(getGetInviteCampaignByIdV1InviteCampaignsInviteCampaignIdGetQueryOptions(campaignId));

  useEffect(() => {
    if (inviteCampaign.country) {
      setCountry(inviteCampaign.country);
    }
    if (inviteCampaign.startDate) {
      setStartDate(convertToUsableDate(inviteCampaign.startDate));
    }
    if (inviteCampaign.startDate) {
      setEndDate(convertToUsableDate(inviteCampaign.startDate));
    }
  }, [inviteCampaign]);

  const updateCampaignMutation = useUpdateInviteCampaignV1InviteCampaignsInviteCampaignIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Invite campaign was successfully updated!");
        navigate({
          to: "/invite-campaigns",
          search: {
            status: inviteCampaign.status as CampaignStatus,
            country: inviteCampaign.country,
          },
        });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const { data: offers, refetch: refetchOffers } = useGetOffersV1OffersGet(
    {
      country: country,
    },
    {
      query: {
        enabled: country !== "",
        initialData: [],
      },
    },
  );

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    if (!inviteCampaign.bannerUrl || !inviteCampaign.thumbnailUrl) {
      return toast.error("Error", { description: "Banner & thumbnail are required!" });
    }
    await updateCampaignMutation.mutateAsync({
      inviteCampaignId: campaignId,
      data: inviteCampaign,
    });
  };

  const page: PageConfig = {
    title: "Edit invite campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => {
          inviteCampaign.country = data;
          setCountry(data);
          refetchOffers();
        },
        value: country,
        options: getCountryOptions(),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: inviteCampaign.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (inviteCampaign.title = event.target.value),
        row: true,
        required: true,
      },
      {
        name: "description",
        label: "Description:",
        helpText: "Campaign description.",
        type: "text-editor",
        change: (data: string) => (inviteCampaign.description = data),
        value: inviteCampaign.description,
        row: true,
        required: true,
        custom: true,
      },
      {
        name: "invitesCount",
        label: "Invites count:",
        type: "number",
        helpText: "How many friends user need to invite to be rewarded.",
        value: inviteCampaign.invitesCount,
        change: (event: React.ChangeEvent<HTMLInputElement>) => (inviteCampaign.invitesCount = +event.target.value),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "offerId",
        label: "OfferId:",
        type: "select",
        helpText: "Reward for completing campaign.",
        change: (e: React.ChangeEvent<HTMLInputElement>) => (inviteCampaign.offerId = e.target.value),
        value: inviteCampaign.offerId,
        options: offers
          ?.sort((offerA, offerB) => (offerA.titleV2.toLowerCase() < offerB.titleV2.toLowerCase() ? -1 : 1))
          .map((offer) => ({ name: `${offer.titleV2} - ${offer.subTitle}`, value: offer._id })),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "campaignLimit",
        label: "Campaign limit:",
        type: "number",
        helpText: "Campaign limit defines how many times user can receive reward. After reaching limit user will receive only invite points.",
        value: inviteCampaign.campaignLimit,
        change: (e: React.ChangeEvent<HTMLInputElement>) => (inviteCampaign.campaignLimit = +e.target.value),
        row: true,
        min: 1,
      },
      {
        name: "startDate",
        label: "Start date:",
        helpText: "Start of the period when campaign will be active.",
        type: "datetime-picker",
        value: startDate,
        change: (date: Date) => {
          setStartDate(date);
          inviteCampaign.startDate = date.toISOString();
        },
        custom: true,
      },
      {
        name: "endDate",
        label: "End date:",
        helpText: "End of the period when campaign will be disabled.",
        type: "datetime-picker",
        value: endDate,
        change: (date: Date) => {
          setEndDate(date);
          inviteCampaign.endDate = date.toISOString();
        },
        custom: true,
      },
      {
        name: "banner",
        label: "Banner:",
        helpText: "Campaign banner.",
        type: "image-cropper",
        imageType: ImageType.invite_campaign_banner,
        value: inviteCampaign.bannerUrl,
        change: (imageUrl: string) => (inviteCampaign.bannerUrl = imageUrl),
        row: true,
        custom: true,
        required: true,
      },
      {
        name: "thumbnail",
        label: "Thumbnail:",
        helpText: "Campaign thumbnail.",
        type: "image-cropper",
        imageType: ImageType.invite_campaign_thumbnail,
        value: inviteCampaign.thumbnailUrl,
        change: (imageUrl: string | null) => (inviteCampaign.thumbnailUrl = imageUrl),
        row: true,
        custom: true,
        required: true,
      },
    ],
    onSubmit: onSubmit,
    submitText: "Save",
    name: "edit-invite-campaign-link",
  };

  return <CreateEditPage loading={isFetching} page={page} form={form} />;
}
