import { createFileRoute, Outlet } from "@tanstack/react-router";

import { getGetMarketplaceDataV1MarketplaceGetQueryOptions } from "~/api/marketplace/marketplace.gen";
import SplashPage from "~/pages/Splash/Splash";
import { MarketplaceProvider } from "~/providers/marketplace";

export const Route = createFileRoute("/_auth/$market/marketplace")({
  loader: ({ context: { queryClient }, params: { market } }) => {
    queryClient.ensureQueryData(getGetMarketplaceDataV1MarketplaceGetQueryOptions({ market: market }));
  },
  component: () => <MarketPlaceLayout />,
  pendingComponent: () => <SplashPage />,
  wrapInSuspense: true,
});

function MarketPlaceLayout() {
  const { market } = Route.useParams();
  return (
    <MarketplaceProvider market={market}>
      <Outlet />
    </MarketplaceProvider>
  );
}
