import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { ImageType, PartnerAppModel, PartnerStatus } from "~/api/model";
import { useCreatePartnerAppV1PartnerAppsPost } from "~/api/partner-app/partner-app.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/partner-apps/create")({
  component: () => <CreatePartnerAppPage />,
});

const CreatePartnerAppPage = () => {
  const navigate = useNavigate();

  const [partnerAppCampaign, setPartnerAppCampaign] = useState<PartnerAppModel>({
    country: "",
    title: "",
    description: "",
    deepLink: "",
    status: PartnerStatus.Unpublished,
    reward: 0,
    maximumDownloads: 0,
    downloadsCount: 0,
    appLogoUrl: "",
    appImageUrl: "",
    platform: {
      android: true,
      ios: true,
    },
  });
  const createPartnerAppMutation = useCreatePartnerAppV1PartnerAppsPost({
    mutation: {
      onSuccess: () => {
        toast("Partner app campaign was successfully created!");
        navigate({ to: `/partner-apps?status=Unpublished&country=${partnerAppCampaign.country}` });
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const onSubmit = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    await createPartnerAppMutation.mutateAsync({ data: partnerAppCampaign });
  };

  const page: PageConfig = {
    title: "Create partner app campaign",
    description: "Use this elements, if you want to show some hints or additional information",
  };

  const form: FormConfig = {
    fields: [
      {
        name: "country",
        label: "Country:",
        type: "search-select",
        helpText: "Specify campaign country.",
        change: (data: string) => setPartnerAppCampaign({ ...partnerAppCampaign, country: data }),
        value: partnerAppCampaign.country,
        options: getCountryOptions(),
        empty: " ",
        row: true,
        required: true,
      },
      {
        name: "title",
        label: "Title:",
        type: "text",
        helpText: "Campaign title.",
        value: partnerAppCampaign.title,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setPartnerAppCampaign({ ...partnerAppCampaign, title: event.target.value }),
        row: true,
        required: true,
      },
      {
        name: "description",
        label: "Description:",
        type: "text-editor",
        helpText: "Campaign description.",
        value: partnerAppCampaign.description,
        change: (data: any) => setPartnerAppCampaign({ ...partnerAppCampaign, description: data }),
        row: true,
        required: true,
        custom: true,
      },
      {
        name: "deepLink",
        label: "Deep link:",
        type: "text",
        helpText: "Campaign deep link.",
        value: partnerAppCampaign.deepLink,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setPartnerAppCampaign({ ...partnerAppCampaign, deepLink: event.target.value }),
        row: true,
        required: true,
      },
      {
        name: "reward",
        label: "Reward:",
        type: "number",
        helpText: "Reward points amount.",
        value: partnerAppCampaign.reward,
        change: (event: React.ChangeEvent<HTMLInputElement>) => setPartnerAppCampaign({ ...partnerAppCampaign, reward: +event.target.value }),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "maxDownloads",
        label: "Max downloads:",
        type: "number",
        helpText: "Maximum downloads count.",
        value: partnerAppCampaign.maximumDownloads,
        change: (event: React.ChangeEvent<HTMLInputElement>) =>
          setPartnerAppCampaign({ ...partnerAppCampaign, maximumDownloads: +event.target.value }),
        row: true,
        min: 1,
        required: true,
      },
      {
        name: "ios",
        label: "Platform IOS:",
        helpText: "Show/hide campaign for IOS devices.",
        type: "checkbox",
        change: () =>
          setPartnerAppCampaign({
            ...partnerAppCampaign,
            platform: { ...partnerAppCampaign.platform, ios: !partnerAppCampaign.platform?.ios || true },
          }),
        value: partnerAppCampaign.platform?.ios || false,
      },
      {
        name: "android",
        label: "Platform Android:",
        helpText: "Show/hide campaign for Android devices.",
        type: "checkbox",
        change: () =>
          setPartnerAppCampaign({
            ...partnerAppCampaign,
            platform: { ...partnerAppCampaign.platform, android: !partnerAppCampaign.platform?.android || true },
          }),
        value: partnerAppCampaign.platform?.android || false,
      },

      {
        name: "appLogo",
        label: "App Logo:",
        helpText: "Campaign logo.",
        type: "image-cropper",
        imageType: ImageType.partner_app_logo,
        value: partnerAppCampaign.appLogoUrl,
        change: (imageUrl: string) => setPartnerAppCampaign({ ...partnerAppCampaign, appLogoUrl: imageUrl }),
        row: true,
        custom: true,
      },
      {
        name: "appImage",
        label: "App image:",
        helpText: "Campaign image.",
        type: "image-cropper",
        imageType: ImageType.partner_app_image,
        value: partnerAppCampaign.appImageUrl,
        change: (imageUrl: string) => setPartnerAppCampaign({ ...partnerAppCampaign, appImageUrl: imageUrl }),
        row: true,
        custom: true,
      },
    ],
    onSubmit: onSubmit,
    submitText: "Save",
    name: "create-app-campaign-link",
  };

  return <CreateEditPage loading={false} page={page} form={form} />;
};
