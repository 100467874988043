import steplerIcon from "../../assets/icons/stepler.png";

const SplashPage = () => {
  return (
    <section className="bg-background flex items-center h-screen justify-center w-full">
      <img className="animate-pulse" src={steplerIcon} />
    </section>
  );
};

export default SplashPage;
