import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Icon } from "./icon/icon";
import { Button } from "./ui/button";
import ImagePreviewUploader from "./image_preview_upload";

import { ImageType } from "~/api/model";

type SortableImagePreviewUploaderProps = {
  id: string;
  imageType: ImageType;
  onChange: (image: string | null) => void;
  defaultImage: string | null;
};

const SortableImagePreviewUploader = React.forwardRef<any, SortableImagePreviewUploaderProps>((props, ref) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="group relative">
      <Button size="sm" {...attributes} {...listeners} type="button" className="absolute left-3 top-3 z-10 hidden p-2 group-hover:block">
        <Icon icon="DragAndDrop" />
      </Button>
      <ImagePreviewUploader imageType={props.imageType} onChange={props.onChange} defaultImage={props.defaultImage} ref={ref} />
    </div>
  );
});

export default SortableImagePreviewUploader;
