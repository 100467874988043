import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { createFileRoute, redirect } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { BackendSelector } from "@/backend_change";
import { Button } from "@/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/ui/card";
import { Input } from "@/ui/input";
import { Label } from "@/ui/label";

import { sendLoginEmail } from "~/api/auth";
import { parseFastAPIError } from "~/helpers/parse-errors";

export const Route = createFileRoute("/login")({
  validateSearch: z.object({
    redirect: z.string().optional().catch(""),
  }),
  beforeLoad: ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirect({ to: "/dashboard" });
    }
  },
  component: () => <LoginPage />,
});

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [emailSend, setEmailSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const search = Route.useSearch();

  const requestLoginEmail = useMutation({
    mutationFn: sendLoginEmail,
    onSuccess: () => {
      setIsLoading(false);
      setEmailSend(true);
    },
    onError: function (error: AxiosError) {
      setIsLoading(false);
      toast.error("Error", { description: parseFastAPIError(error) });
    },
  });

  const onSubmit = async () => {
    // Check if email contains @
    let localEmail = email;
    if ("@" !== email.match(/@/)?.[0]) {
      localEmail = email + "@stepler.io";
      setEmail(localEmail);
    }

    setIsLoading(true);

    // eslint-disable-next-line camelcase
    requestLoginEmail.mutate({ email: localEmail, login_url: search.redirect || "/dashboard" });
  };

  return (
    <section className="flex h-screen w-full items-center justify-center">
      <Card className="w-full max-w-sm">
        <CardHeader>
          <CardTitle className="text-2xl">Login</CardTitle>
          <CardDescription>Enter your email below to login to your account.</CardDescription>
        </CardHeader>
        {emailSend && (
          <CardContent>
            <p className="text-foreground">We have sent you an email with a link to login. You can close this tab</p>
          </CardContent>
        )}
        {!emailSend && (
          <>
            <CardContent className="grid gap-4">
              <BackendSelector />
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  placeholder="email@stepler.io"
                  value={email}
                  onChange={(event) => setEmail(event.currentTarget.value)}
                  required
                  onKeyDown={(event) => event.key === "Enter" && onSubmit()}
                />
              </div>
            </CardContent>
            <CardFooter>
              <Button className="w-full" onClick={onSubmit} disabled={isLoading}>
                {isLoading ? "Sending..." : "Login"}
              </Button>
            </CardFooter>
          </>
        )}
      </Card>
    </section>
  );
};
