import { createFileRoute } from "@tanstack/react-router";

import { OfferRelation, OfferStatus } from "~/api/model";
import OfferForm, { OfferEdit } from "~/pages/Offers/offerPart/OfferForm";

export const Route = createFileRoute("/_auth/$market/marketplace/rewards/create")({
  validateSearch: (search: Record<string, any>): OfferEdit => ({
    price: search.price || 1,
    subTitle: search.subTitle || "",
    companyDescription: search.companyDescription || "",
    shortDescription: search.shortDescription || "",
    url: search.url || undefined,
    country: search.country || "SE",
    categories: search.categories || [],
    tags: search.tags || [],
    steplerOnly: search.steplerOnly || false,
    containsSubscription: search.containsSubscription || false,
    titleV2: search.titleV2 || "",
    longDescriptionV2: search.longDescriptionV2 || "",
    amount: search.amount || 1,
    order: search.order || undefined,
    purchaseCount: search.purchaseCount || undefined,
    publishDate: search.publishDate ? new Date(search.publishDate) : new Date(),
    unPublishDate: search.unPublishDate ? new Date(search.unPublishDate) : new Date(),
    qty: search.qty || undefined,
    shipping: search.shipping || undefined,
    valid: search.valid || undefined,
    other: search.other || undefined,
    value: search.value || undefined,
    ios: search.ios || true,
    android: search.android || true,
    status: search.status || OfferStatus.Unpublished,
    hideInList: search.hideInList || false,
    relation: search.relation || OfferRelation.Partner_offer,
    logoUrl: search.logoUrl || null,
    thumbnailUrl: search.thumbnailUrl || "",
    bannerUrl1: search.bannerUrl1 || null,
    bannerUrl2: search.bannerUrl2 || null,
    bannerUrl3: search.bannerUrl3 || null,
  }),
  component: () => <CreateOfferPage />,
});

function CreateOfferPage() {
  const search = Route.useSearch();
  const offer: OfferEdit = { ...search };

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section>
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">{search.subTitle ? "Duplicate Reward" : "Create Reward"}</h2>
          <p className="text-foreground">Create a reward</p>
        </div>
        <div className="button-group"></div>
      </section>
      <OfferForm offer={offer} />
    </section>
  );
}
