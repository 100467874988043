import { memo } from "react";
import Select, { GroupBase, MultiValue, StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";

export interface Options {
  value: any;
  label: string;
}

type Props = {
  options?: MultiValue<Options>;
  value: MultiValue<Options>;
  disabled?: boolean;
  onChange: (e: any) => void;
  creatable?: boolean;
};

const styles: StylesConfig<Options, true, GroupBase<Options>> | undefined = {
  clearIndicator: (baseStyles) => ({
    ...baseStyles,
    "&:hover": {
      ...baseStyles[":hover"],
      color: "var(--red)",
    },
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    "&:hover": {
      ...baseStyles[":hover"],
      color: "var(--accent-color)",
    },
  }),
  control: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
    background: "var(--multiselect-background)",
    borderRadius: "none",
    border: "1px solid var(--input-border-color)",
    boxShadow: "none",
    "&:hover": {
      ...baseStyles[":hover"],
      border: "1px solid var(--accent-color)",
      boxShadow: "0 0 0 1px var(--accent-color)",
    },
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    background: "var(--multiselect-background)",
    "&:hover": {
      ...baseStyles,
      backgroundColor: "var(--multiselect-option-container)",
    },
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: "var(--primary-text-color)",
    fontSize: "12px",
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    background: "var(--multiselect-background)",
    color: "var(--primary-text-color)",
    fontSize: "12px",
  }),
  multiValue: (baseStyles) => ({
    ...baseStyles,
    background: "var(--multiselect-option-container)",
  }),
  multiValueLabel: (baseStyles) => ({
    ...baseStyles,
    color: "var(--primary-text-color)",
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    background: "var(--multiselect-background)",
    color: "var(--primary-text-color)",
    "&:active": {
      backgroundColor: "var(--multiselect-option-container)",
    },
    "&:hover": {
      backgroundColor: "var(--multiselect-option-container)",
    },
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    width: "100%",
  }),
};

export const MultiSelect = memo(({ options, value, disabled, onChange, creatable = false }: Props) => {
  return creatable ? (
    <CreatableSelect styles={styles} defaultValue={value} isDisabled={disabled} isMulti options={options} onChange={onChange} />
  ) : (
    <Select styles={styles} defaultValue={value} isDisabled={disabled} isMulti options={options} onChange={onChange} />
  );
});
