import * as React from "react";
import ReactCountryFlag from "react-country-flag";
import { Check, ChevronsUpDown } from "lucide-react";

import { Button } from "@/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/ui/popover";

import { cn } from "~/helpers";

export type ComboboxOption = {
  value: string | undefined;
  label: string;
  type?: string;
};

export function Combobox({
  placeholder,
  options,
  onChange,
  value,
  className,
  showFlag,
}: {
  searchName: string;
  placeholder: string;
  options: ComboboxOption[];
  onChange: (value: ComboboxOption) => void;
  value?: string | null | undefined;
  className?: string;
  showFlag?: boolean;
}) {
  const [open, setOpen] = React.useState(false);

  if (options.length === 0) return null;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="input" size={"input"} className={cn("justify-between", className)} role="combobox" aria-expanded={open}>
          <div className="flex gap-1.5">
            {showFlag && value && <ReactCountryFlag countryCode={value} svg />}
            {value ? options.find((option) => option.value === value)?.label : placeholder}
          </div>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("w-full p-0", className)}>
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            <CommandGroup>
              {options.map((option) => {
                return (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => {
                      onChange(option);
                      setOpen(false);
                    }}
                    style={{
                      pointerEvents: "auto",
                      opacity: 1,
                    }}
                    className="flex cursor-pointer gap-1.5"
                    keywords={[option.label]}
                  >
                    <Check className={cn("h-4 w-4", value === option.value ? "opacity-100" : "opacity-0")} />
                    {showFlag && option.value && <ReactCountryFlag countryCode={option.value} svg />}
                    {option.label}
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
