import { useState } from "react";
import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { useCreateUserV1UsersPost } from "~/api/users/users.gen";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { Field } from "~/oldComponents/form/types";
import { CreateEditPage } from "~/oldComponents/layout/CreateEditLayout";
import { FormConfig, PageConfig } from "~/oldComponents/layout/types";

export const Route = createFileRoute("/_auth/users/admin/create")({
  component: () => <CreateAdminPage />,
});

function CreateAdminPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("@stepler.io");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [loadingState, setLoadingState] = useState(false);

  const createAdminUserMutation = useCreateUserV1UsersPost({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("Admin user was successfully created!");
        navigate({ to: "/users/admin" });
      },
      onSettled: () => {
        setLoadingState(false);
      },
    },
  });

  const onUserCreate = async (event: React.FormEvent | undefined) => {
    event?.preventDefault();
    setLoadingState(true);
    createAdminUserMutation.mutate({ data: { email: email, profile: { firstName: firstname, lastName: lastname }, roles: ["User", "Admin"] } });
  };

  const pageConfig: PageConfig = {
    title: "Create new Admin User",
    description: `Instructions:<br>
    - email domain should be equial to @stepler.io;<br>
    - email must be connected only to 1 user account;`,
  };

  const fields: Field[] = [
    {
      name: "email",
      label: "Email",
      helpText: "The email that the admin can login with. Email domain should be equal to @stepler.io",
      type: "email",
      required: true,
      value: email,
      change: (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
    },
    {
      name: "firstname",
      label: "Firstname",
      helpText: "The firstname of the admin",
      type: "text",
      required: true,
      value: firstname,
      change: (e: React.ChangeEvent<HTMLInputElement>) => setFirstname(e.target.value),
    },
    {
      name: "lastname",
      label: "Lastname",
      helpText: "The lastname of the admin",
      type: "text",
      required: true,
      value: lastname,
      change: (e: React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value),
    },
  ];

  const formConfig: FormConfig = {
    fields: fields,
    name: "add-admin-user",
    onSubmit: onUserCreate,
    submitText: "Create admin user",
    vertical: true,
  };

  return <CreateEditPage page={pageConfig} loading={loadingState} form={formConfig} />;
}
