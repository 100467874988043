import React, { useEffect } from "react";
import { QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { TanStackRouterDevtools } from "@/TanStackRouterDevtools";
import { Toaster } from "@/ui/sonner";

import { refreshTokenCall } from "~/api/auth";
import { AXIOS_INSTANCE } from "~/api/base";
import { AuthContext, useAuth } from "~/providers/auth";
import { ThemeProvider } from "~/providers/theme";

export const Route = createRootRouteWithContext<{
  auth: AuthContext;
  queryClient: QueryClient;
}>()({
  component: () => <Root />,
});

function Root() {
  const { refreshToken, accessToken, setAuth, logout } = useAuth();
  const [refreshingToken, setRefreshingToken] = React.useState(false);

  useEffect(() => {
    document.title = `Stepler Admin - ${import.meta.env.VITE_REACT_APP_NODE_ENV}`;
  }, []);

  const refreshAuthToken = async () => {
    if (!refreshingToken && refreshToken) {
      try {
        const decoded: JwtPayload = jwtDecode(refreshToken);
        setRefreshingToken(true);

        const response = await refreshTokenCall({ refreshToken: refreshToken, userId: decoded.iss as string });
        setAuth({ accessToken: response.access_token, refreshToken: response.refresh_token, user: response.user });
        return response;
      } catch {
        logout();
      } finally {
        setRefreshingToken(false);
      }
    }
  };

  const requestHandlers = AXIOS_INSTANCE.interceptors.request as any;
  if (requestHandlers.handlers.length === 0) {
    AXIOS_INSTANCE.interceptors.request.use(
      async (config) => {
        if (refreshToken && accessToken) {
          const decoded: JwtPayload = jwtDecode(accessToken);
          config.headers.set("userid", decoded.iss as string);
          if (decoded.exp && decoded.exp * 1000 <= Date.now()) {
            const response = await refreshAuthToken();
            config.headers.setAuthorization(`Bearer ${response.access_token}`);
          } else {
            config.headers.setAuthorization(`Bearer ${accessToken}`);
          }
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  }

  const responseHandlers = AXIOS_INSTANCE.interceptors.response as any;
  if (responseHandlers.handlers.length === 0) {
    AXIOS_INSTANCE.interceptors.response.use(
      (response) => {
        return response;
      },
      async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          originalRequest._retry = true;
          const response = await refreshAuthToken();
          AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${response.access_token}`;
          return AXIOS_INSTANCE(originalRequest);
        }
        return Promise.reject(error);
      },
    );
  }

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <Outlet />
        <Toaster />
      </ThemeProvider>
      <ReactQueryDevtools />
      <TanStackRouterDevtools />
    </>
  );
}
