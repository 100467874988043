import { memo } from "react";

import { Option } from "../form/types";

import "./select.scss";

type OwnProps = {
  empty?: string;
  optionList: Option[];
  selectedValue: number | string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

export const Select = memo(({ name, optionList, selectedValue, empty, className, ...selectProps }: Props) => {
  return (
    <select
      key={`select-${name}`}
      name={name}
      className={`select ${className} bg-background p-4 text-foreground`}
      {...selectProps}
      defaultValue={selectedValue}
    >
      {empty && <option value="">{empty}</option>}
      {optionList.map((option) => (
        <option key={`${name}-${option.value}`} value={option.value}>
          {option.name}
        </option>
      ))}
    </select>
  );
});
