// import { redirect } from "react-router-dom";
import { TableBuilder } from "./types";

type OwnProps = {
  tableConfig: TableBuilder[];
  objects: Array<any> | undefined | null;
  link?: any;
  emptyMessage?: string;
  state?: any;
  tableTitle?: string;
  tableStyles?: string;
  rowStyles?: any;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TableRow = ({ objects, link, tableConfig, emptyMessage, rowStyles, id }: Props) => {
  const renderRow = (link: any | undefined, item: any, config: TableBuilder, index: number) => {
    const data = config.format(item, config.key) || "";

    let title = data;
    if (config?.title) {
      title = config.title(item);
    }

    if (link && !config?.actionRow) {
      return (
        <td onClick={() => link(item)} key={`table-cell-${index}-${id}`} className="table__cell table__cell--pointer" title={title.toString()}>
          {data}
        </td>
      );
    } else {
      return (
        <td key={`table-cell-${index}-${id}`} className="table__cell" title={title.toString()}>
          {data}
        </td>
      );
    }
  };

  if (!objects || objects.length < 1) {
    return (
      <tr className="table__row border-b hover:bg-background/50" role="table" key="empty">
        <td className="table__cell" colSpan={100}>
          {emptyMessage ? emptyMessage : "No results found. Please adjust the search parameters or start searching"}
        </td>
      </tr>
    );
  } else {
    return (
      <>
        {objects?.map((item, i) => {
          const rowClassName = rowStyles && rowStyles(item);

          return (
            <tr className={`table__row ${rowClassName} border-b hover:bg-background/50`} key={`table-row-${i}-${item._id}`} role="table">
              {tableConfig.map((config, j) => {
                return renderRow(link, item, config, j);
              })}
            </tr>
          );
        })}
      </>
    );
  }
};

export default TableRow;
