import { createFileRoute } from "@tanstack/react-router";

import { OfferStatus } from "~/api/model";
import BonusOfferForm, { BonusOfferEdit } from "~/pages/Offers/offerPart/BonusOfferForm";

export const Route = createFileRoute("/_auth/$market/marketplace/rewards/bonus/create")({
  validateSearch: (search: Record<string, any>): BonusOfferEdit => ({
    price: search.price || 1,
    subTitle: search.subTitle || "",
    companyDescription: search.companyDescription || "",
    shortDescription: search.shortDescription || "",
    url: search.url || undefined,
    country: search.country || "SE",
    categories: search.categories || [],
    tags: search.tags || [],
    transactionTitle: search.transactionTitle || "",
    titleV2: search.titleV2 || "",
    longDescriptionV2: search.longDescriptionV2 || "",
    order: search.order || undefined,
    publishDate: search.publishDate ? new Date(search.publishDate) : new Date(),
    unPublishDate: search.unPublishDate ? new Date(search.unPublishDate) : new Date(),
    ios: search.platform?.ios || true,
    android: search.platform?.android || true,
    status: search.status || OfferStatus.Unpublished,
    hideInList: search.hideInList || false,
    logoUrl: search.logoUrl || null,
    thumbnailUrl: search.thumbnailUrl || "",
    bannerUrl1: search.bannerUrl1 || null,
    bannerUrl2: search.bannerUrl2 || null,
    bannerUrl3: search.bannerUrl3 || null,
  }),
  component: () => <CreateBonusOfferPage />,
});

function CreateBonusOfferPage() {
  const search = Route.useSearch();
  const offer: BonusOfferEdit = { ...search };

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section>
        <div className="header-titles_wrapper gap-4">
          <h2 className="text-foreground">Create Bonus Reward</h2>
          <p className="text-foreground">Create a bonus reward</p>
        </div>
        <div className="button-group"></div>
      </section>
      <BonusOfferForm
        offer={{
          price:offer.price,
          subTitle:offer.subTitle,
          companyDescription:offer.companyDescription,
          shortDescription:offer.shortDescription,
          url:offer.url,
          country:offer.country,
          titleV2:offer.titleV2,
          transactionTitle:offer.transactionTitle,
          longDescriptionV2:offer.longDescriptionV2,
          order:offer.order,
          publishDate:offer.publishDate,
          unPublishDate:offer.unPublishDate,
          ios:offer.ios,
          android:offer.android,
          status:offer.status,
          hideInList:offer.hideInList,
          logoUrl:offer.logoUrl,
          thumbnailUrl:offer.thumbnailUrl,
          bannerUrl1:offer.bannerUrl1,
          bannerUrl2:offer.bannerUrl2,
          bannerUrl3:offer.bannerUrl3,
          categories:offer.categories,
          tags:offer.tags,
        }}
      />
    </section>
  );
}
