import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "@tanstack/react-router";
import { z } from "zod";

import { Icon } from "@/icon/icon";
import ImagePreviewUploader from "@/image_preview_upload";
import { Button } from "@/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/ui/form";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { RecommendedList } from "../CollectionsParts/types";

import { ImageType, RecommendedModel, RecommendedType } from "~/api/model";
import { getCategoryName } from "~/helpers/category";
import { useMarketplace } from "~/providers/marketplace";

const FormSchema = z.object({
  type: z.enum([RecommendedType.Url, RecommendedType.Category, RecommendedType.Tag, RecommendedType.Offer]),
  data: z.string(),
  image: z.nullable(z.string()),
});

const RecommendedForm = ({
  order,
  recommended,
  allRecommended,
  updateRecommended,
}: {
  order: number;
  recommended: RecommendedModel | undefined;
  allRecommended: RecommendedList;
  updateRecommended: any;
}) => {
  const params = useParams({ from: "/_auth/$market/marketplace" });
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: order });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };
  const { activeRewards, tags, categories } = useMarketplace();

  const [open, setOpen] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      type: recommended?.type || RecommendedType.Url,
      data: recommended?.data || undefined,
      image: recommended?.image || null,
    },
  });

  function getImage(recommended: any) {
    if (recommended?.image) {
      return `${import.meta.env.VITE_ASSETS_DOMAIN}/${recommended.image}`;
    }
    if (recommended?.type === "Offer") {
      const offer = activeRewards.find((offer: any) => offer._id === recommended.data);
      if (offer) {
        return `${import.meta.env.VITE_ASSETS_DOMAIN}/${offer.thumbnailUrl}`;
      }
    }
    return "";
  }

  useEffect(() => {
    form.setValue("type", recommended?.type || RecommendedType.Url);
    form.setValue("data", recommended?.data || "");
    form.setValue("image", recommended?.image || null);
  }, [recommended, recommended?.data]);

  const onSubmit = (values: z.infer<typeof FormSchema>) => {
    const data: any = {
      type: values.type,
      data: values.data,
      image: values.image,
      market: params.market,
      order: order,
      _id: recommended?._id,
    };

    const modified = [...allRecommended];
    modified[order - 1] = data;
    updateRecommended(modified);
    setOpen(false);
  };

  const formData = useWatch({
    control: form.control,
  });

  function deleteRecommendedFn() {
    const modified = [...allRecommended];
    modified[order - 1] = undefined;
    updateRecommended(modified);
  }

  return (
    <>
      {recommended && (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="group relative aspect-video">
          <Button
            className="absolute left-2 top-2 hidden p-1 group-hover:block"
            onClick={() => {
              setOpen(true);
            }}
          >
            <Icon icon="Edit" />
          </Button>
          <Button variant={"destructive"} className="absolute right-2 top-2 hidden p-1 group-hover:block" onClick={() => deleteRecommendedFn()}>
            <Icon icon="Trash" />
          </Button>
          <img src={getImage(recommended)} key={recommended.data} alt="recommended" className="aspect-video w-full rounded-lg object-cover" />
        </div>
      )}
      {!recommended && (
        <Button
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          variant={"accent"}
          className="flex aspect-video flex-col gap-2"
          onClick={() => {
            setOpen(true);
          }}
        >
          <Icon icon="Add" />
          Add Item
        </Button>
      )}
      <Dialog open={open} onOpenChange={setOpen}>
        <></>
        <DialogContent className="max-w-6/12 w-6/12">
          <DialogHeader>
            <DialogTitle>Add recommended item</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex flex-col gap-10">
                <FormField
                  control={form.control}
                  name="type"
                  render={({ field }) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Type *</FormLabel>
                      <Select onValueChange={field.onChange} defaultValue={field.value}>
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Type" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value={RecommendedType.Url}>Url</SelectItem>
                          <SelectItem value={RecommendedType.Category}>Category</SelectItem>
                          <SelectItem value={RecommendedType.Tag}>Tag</SelectItem>
                          <SelectItem value={RecommendedType.Offer}>Reward</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {formData.type === "Url" && (
                  <FormField
                    control={form.control}
                    name="data"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>Company Url *</FormLabel>
                        <FormControl>
                          <Input placeholder="https://example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {formData.type === "Category" && (
                  <FormField
                    control={form.control}
                    name="data"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>Category *</FormLabel>
                        <FormControl>
                          <Select onValueChange={(value) => form.setValue("data", value)} defaultValue={field.value}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a category" />
                            </SelectTrigger>
                            <SelectContent>
                              {categories.map((category: any) => (
                                <SelectItem key={category.category} value={category.category}>
                                  {getCategoryName(category.category)}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {formData.type === "Tag" && (
                  <FormField
                    control={form.control}
                    name="data"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>Tag *</FormLabel>
                        <FormControl>
                          <Select onValueChange={(value) => form.setValue("data", value)} defaultValue={field.value}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a tag" />
                            </SelectTrigger>
                            <SelectContent>
                              {tags.map((tag: any) => (
                                <SelectItem key={tag._id} value={tag._id}>
                                  {tag.name}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {formData.type === "Offer" && (
                  <FormField
                    control={form.control}
                    name="data"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel>Reward *</FormLabel>
                        <FormControl>
                          <Select onValueChange={(value) => form.setValue("data", value)} defaultValue={field.value}>
                            <SelectTrigger>
                              <SelectValue placeholder="Select a reward" />
                            </SelectTrigger>
                            <SelectContent>
                              {activeRewards
                                .filter((offer) => !offer.isBonus)
                                .map((offer: any) => (
                                  <SelectItem key={offer._id} value={offer._id}>
                                    {offer.titleV2} - {offer.subTitle}
                                  </SelectItem>
                                ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
                {formData.type && (
                  <FormField
                    control={form.control}
                    name="image"
                    render={({ field }) => (
                      <FormItem className="flex flex-col gap-2">
                        <FormLabel className="flex justify-between">
                          <span>Custom image</span>
                          <span className="text-xs">16:9 - Max 1.5 mb</span>
                        </FormLabel>
                        <FormControl>
                          <ImagePreviewUploader
                            className="aspect-video w-48"
                            imageType={ImageType.recommended_image}
                            onChange={(image: string | null) => {
                              form.setValue("image", image);
                            }}
                            defaultImage={field.value}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}
              </div>
              <DialogFooter>
                <Button type="submit">Save changes</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecommendedForm;
