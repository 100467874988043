import { Link, ToPathOption } from "@tanstack/react-router";

function Tab({ active, to, title }: { active: boolean; to: ToPathOption; title: string }) {
  return (
    <Link
      className="py-2.5 px-10 bg-tab text-tab-foreground rounded-t-lg opacity-50 hover:opacity-70 transition-opacity data-[state=active]:opacity-100"
      data-state={active ? "active" : ""}
      to={to}
    >
      {title}
    </Link>
  );
}

export default Tab;
