import { memo } from "react";

import "./input.scss";

type OwnProps = {
  wrapperClassName?: string;
};

type Props = OwnProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export const Input = memo(({ name, value, type, disabled, ...restProps }: Props) => {
  if (!value) {
    value = "";
  }

  return (
    <input
      id={name}
      name={name}
      type={type}
      defaultValue={value !== undefined ? value : undefined}
      disabled={disabled}
      {...restProps}
      className={"input bg-background text-foreground autofill:bg-background autofill:text-foreground"}
    />
  );
});
