import * as React from "react";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import clsx from "clsx";

import Header from "@/nav/Header";
import { Sidebar } from "@/nav/SideBar";

import "../styles/layout.scss";

import CountriesProvider from "~/providers/countries";

export const Route = createFileRoute("/_auth")({
  beforeLoad: ({ context, location }) => {
    if (!context.auth.isAuthenticated) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
  component: AuthLayout,
});

function AuthLayout() {
  const [menuOpened, setMenuOpened] = React.useState(localStorage.getItem("menu-opened") === "true");

  React.useEffect(() => {
    localStorage.setItem("menu-opened", menuOpened.toString());
  }, [menuOpened]);

  const onMenuChange = () => {
    setMenuOpened(!menuOpened);
  };

  const bodyClasses = clsx({
    body: true,
    "body--open": menuOpened,
  });

  return (
    <CountriesProvider>
      <Header onMenuChange={onMenuChange} />
      <section className={bodyClasses}>
        <Sidebar menuOpened={menuOpened} key="sidebar" />
        <div className="h-pageHeight overflow-y-auto">
          <Outlet />
        </div>
      </section>
    </CountriesProvider>
  );
}
