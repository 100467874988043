import { useEffect, useState } from "react";
import { useSuspenseQueries } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { addYears } from "date-fns";
import { toast } from "sonner";

import { Icon } from "@/icon/icon";

import { getGetMarketplaceDataV1MarketplaceGetQueryOptions } from "~/api/marketplace/marketplace.gen";
import { CategoryMarketModel, OfferModel, OfferRelation, OfferStatus, TagModel } from "~/api/model";
import { getGetOfferByIdV1OffersOfferIdGetQueryOptions } from "~/api/offers/offers.gen";
import { convertToUsableDate } from "~/helpers/date-formatting";
import OfferForm from "~/pages/Offers/offerPart/OfferForm";
import SplashPage from "~/pages/Splash/Splash";
import { OfferWithCategoriesAndTags } from "~/providers/marketplace";

export const Route = createFileRoute("/_auth/$market/marketplace/rewards/$rewardId/")({
  loader: ({ context: { queryClient }, params: { rewardId, market } }) => {
    queryClient.ensureQueryData(getGetOfferByIdV1OffersOfferIdGetQueryOptions(rewardId));
    queryClient.ensureQueryData(getGetMarketplaceDataV1MarketplaceGetQueryOptions({ market: market }));
  },
  component: () => <EditOfferPage />,
});

function EditOfferPage() {
  const rewardId = Route.useParams().rewardId;
  const market = Route.useParams().market;

  const [{ data: offer, refetch, error, success }, { data }]: [
    { data: OfferModel; refetch: any; error: any; success: any },
    {
      data: {
        categories: CategoryMarketModel[];
        tags: TagModel[];
      };
    },
  ] = useSuspenseQueries({
    queries: [getGetOfferByIdV1OffersOfferIdGetQueryOptions(rewardId), getGetMarketplaceDataV1MarketplaceGetQueryOptions({ market: market })],
  });

  const [reward, setReward] = useState<OfferWithCategoriesAndTags>();

  useEffect(() => {
    if (offer && data.tags.length > 0 && data.categories.length > 0) {
      const offerCategories = data.categories.filter((category) => category.rewardOrder.includes(offer._id || ""));
      const offerTags = data.tags.filter((tag) => tag.rewardOrder?.includes(offer._id || ""));
      setReward({
        ...offer,
        id: offer._id?.toString() || undefined,
        fullTitle: `${offer.titleV2} - ${offer.subTitle}`,
        categories: offerCategories,
        categoryIds: offerCategories.map((category) => category.category),
        categoryIdsString: offerCategories.map((category) => category.category).join(","),
        tags: offerTags,
        tagIds: offerTags.map((tag) => tag._id?.toString() as string),
        tagNames: offerTags.map((tag) => tag.name?.toString() as string).join(","),
        isBonus: !!(offer.bonus && offer.bonus.transactionTitle),
      });
    }
  }, [success]);

  if (!reward) {
    return <SplashPage />;
  }

  if (error) {
    return <div>Could not load the reward</div>;
  }

  return (
    <section className="flex flex-col gap-10 bg-background px-8 py-10">
      <section>
        <div className="header-titles_wrapper flex gap-4">
          <h2 className="text-foreground">Edit Reward</h2>
          <span className="text-foregroun flex gap-2">
            Reward id: {offer._id}
            <span
              className="text-foregroun flex cursor-pointer items-center"
              onClick={() => {
                navigator.clipboard.writeText(offer._id as string);
                toast("Reward id successfully copied to clipboard!");
              }}
            >
              <Icon icon="Copy" className="h-4 w-4" />
            </span>
          </span>
        </div>
        <div className="button-group"></div>
      </section>
      <OfferForm
        refetch={refetch}
        offerModel={reward}
        offer={{
          _id: rewardId,
          price: reward.price,
          subTitle: reward.subTitle || "",
          companyDescription: reward.companyDescription || "",
          shortDescription: reward.shortDescription,
          url: reward.url || "",
          country: reward.country,
          categories: reward.categoryIds,
          tags: reward.tagIds,
          steplerOnly: reward.steplerOnly || false,
          containsSubscription: reward.containsSubscription || false,
          titleV2: reward.titleV2,
          longDescriptionV2: reward.longDescriptionV2,
          amount: reward.amount,
          order: reward.order || undefined,
          purchaseCount: reward.purchaseCount === null ? undefined : reward.purchaseCount,
          publishDate: convertToUsableDate(reward.publishDate) || new Date(),
          unPublishDate: convertToUsableDate(reward.unPublishDate) || addYears(new Date(), 1),
          qty: reward.terms?.qty || "",
          shipping: reward.terms?.shipping || "",
          valid: reward.terms?.valid || "",
          other: reward.terms?.other || "",
          value: reward.terms?.value || "",
          ios: reward.platform.ios || false,
          android: reward.platform.android || false,
          status: reward.status || OfferStatus.Unpublished,
          hideInList: reward.hideInList || false,
          relation: reward.relation || OfferRelation.Partner_offer,
          logoUrl: reward.logoUrl || "",
          thumbnailUrl: reward.thumbnailUrl,
          bannerUrl1: reward.bannerUrls[0] || null,
          bannerUrl2: reward.bannerUrls[1] || null,
          bannerUrl3: reward.bannerUrls[2] || null,
        }}
      />
    </section>
  );
}
