import { FaPlus, FaTrashCan } from "react-icons/fa6";

import Button from "../button/Button";
import { Input } from "../input/Input";

import "./text-array.scss";

type TextArrayProps = {
  textArray: string[];
  change: (newArray: string[]) => void;
  required?: boolean;
};

export const TextArray = ({ textArray, change, required }: TextArrayProps) => {
  return (
    <div className="text-array-container">
      {textArray.map((item, index) => (
        <div key={`text-array-${index}`} className="text-array-item ">
          <Input
            name={`text-array-${index}`}
            type="text"
            onChange={(e) => {
              const newTextArray = [...textArray];
              newTextArray[index] = e.target.value;
              change(newTextArray);
            }}
            value={item}
            required={required}
          />
          <Button
            styleType="danger"
            size="small"
            onClick={() => {
              const newTextArray = [...textArray];
              newTextArray.splice(index, 1);
              change(newTextArray);
            }}
          >
            <FaTrashCan />
          </Button>
        </div>
      ))}
      <Button size="small">
        <FaPlus onClick={() => change([...textArray, ""])} />
      </Button>
    </div>
  );
};
