import { createContext } from "react";

type ThemeContextType = {
  theme: string;
  toggleTheme: () => void;
};

type CountriesContextType = {
  countries: any[];
};

export const CountriesContext = createContext<CountriesContextType>({
  countries: [],
});

export const ThemeContext = createContext<ThemeContextType>({ theme: "light", toggleTheme: () => {} });
