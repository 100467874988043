"use client";

import { CSSProperties, ReactNode } from "react";
import { closestCenter, DndContext, DragEndEvent, KeyboardSensor, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Cell, ColumnDef, flexRender, Row } from "@tanstack/react-table";

import { Button } from "@/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/ui/table";

import { Icon } from "./icon/icon";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "./ui/select";

import { Reward } from "~/pages/Marketplace/CollectionsParts/types";
import { HighlightedCollectionWithCollection } from "~/providers/marketplace";

type Props = {
  table: any;
  columns: ColumnDef<HighlightedCollectionWithCollection>[] | ColumnDef<Reward>[];
  handleDragEnd: (event: DragEndEvent) => void;
  dataIds: any[];
  hidePagination?: boolean;
  additionalData?: any[];
  addToList?: (id: string) => void;
  searchColumn?: string;
  combobox?: ReactNode;
};

// Cell Component
export const RowDragHandleCell = ({ rowId }: { rowId: string }) => {
  const { attributes, listeners } = useSortable({
    id: rowId,
  });
  return (
    // Alternatively, you could set these attributes on the rows themselves
    <Button variant={"ghost"} {...attributes} {...listeners} type="button">
      <Icon icon="DragAndDrop" />
    </Button>
  );
};

// Row Component
const DraggableRow = ({ row }: { row: Row<any> }) => {
  const { transform, transition, setNodeRef, isDragging } = useSortable({
    id: row.original.id,
  });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform), // let dnd-kit do its thing
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    position: "relative",
  };
  return (
    // connect row ref to dnd-kit, apply important styles
    <TableRow ref={setNodeRef} style={style} data-state={row.getIsSelected() && "selected"}>
      {row.getVisibleCells().map((cell: Cell<any, any>) => {
        if ((cell.column.columnDef as any).onClick) {
          return (
            <TableCell
              key={cell.id}
              style={{ width: cell.column.getSize() }}
              onClick={(event) => (cell.column.columnDef as any).onClick(event, cell.row)}
              className="cursor-pointer"
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        } else {
          return (
            <TableCell key={cell.id} style={{ width: cell.column.getSize() }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </TableCell>
          );
        }
      })}
    </TableRow>
  );
};

export function DnDDataTable({ table, columns, handleDragEnd, dataIds, hidePagination, additionalData, addToList, searchColumn, combobox }: Props) {
  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
  const search = table.getColumn(searchColumn)?.getFilterValue() || "";

  return (
    <DndContext collisionDetection={closestCenter} modifiers={[restrictToVerticalAxis]} onDragEnd={handleDragEnd} sensors={sensors}>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup: any) => (
            <TableRow key={headerGroup.id} className="">
              {headerGroup.headers.map((header: any) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.columnDef.id === "addCombobox" && combobox}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          <TableRow className="border-0">
            <TableCell className="p-3"></TableCell>
          </TableRow>
          <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row: any) => <DraggableRow key={row.id} row={row} />)
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {additionalData && addToList && additionalData.length > 0 ? (
                    <Select onValueChange={(value) => addToList(value)}>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a reward to add" />
                      </SelectTrigger>
                      <SelectContent>
                        {additionalData
                          ?.filter((data) => !table.options.data.find((offer: any) => offer._id === data._id))
                          .filter((data) => {
                            if (search) {
                              return (
                                data.titleV2?.toLowerCase().includes(search?.toLowerCase()) ||
                                data.subTitle?.toLowerCase().includes(search?.toLowerCase())
                              );
                            }
                            return true;
                          })
                          .map((data: any) => (
                            <SelectItem key={data._id} value={data._id}>
                              {data.subTitle} - {data.titleV2}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  ) : (
                    "No results."
                  )}
                </TableCell>
              </TableRow>
            )}
          </SortableContext>
        </TableBody>
      </Table>
      {!hidePagination && (
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            <Select onValueChange={(value) => table.setPagintion({ pageIndex: table.pagination.pageIndex, pageSize: +value })} defaultValue="100">
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Set a page size" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectLabel>Page size</SelectLabel>
                  <SelectItem value="100">100</SelectItem>
                  <SelectItem value="200">200</SelectItem>
                  <SelectItem value="500">500</SelectItem>
                  <SelectItem value="10">10</SelectItem>
                  <SelectItem value="1">1</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
            {/* {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s) selected. */}
          </div>

          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.setPagintion({ pageIndex: table.pagination.pageIndex - 1, pageSize: table.pagination.pageSize })}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.setPagintion({ pageIndex: table.pagination.pageIndex + 1, pageSize: table.pagination.pageSize })}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      )}
    </DndContext>
  );
}
