import { FormEventHandler, useEffect, useState } from "react";
import clsx from "clsx";

import Button from "../button/Button";

import { FormField } from "./FormField";
import { getTypedField } from "./helpers";
import { Field } from "./types";

import "./form.scss";

type Props = {
  name: string;
  onSubmit: FormEventHandler;
  submitText: string;
  readonly?: boolean;
  vertical?: boolean;
  children?: any;
  templateFields: Field[];
  addedRow: any;
  deleteRow: any;
  initialData?: any[];
};

export const FormSet: React.FC<Props> = ({
  name,
  submitText,
  onSubmit,
  vertical,
  templateFields,
  addedRow,
  deleteRow,
  initialData,
  readonly,
  children,
}: Props) => {
  const [fieldRows, setFieldRows] = useState<Array<{ fields: Field[]; data: any; index: number }>>([]);
  const classes = clsx({
    form: true,
    "form--vertical": vertical,
  });

  useEffect(() => {
    if (!initialData) return;
    const allRows = [];
    for (const [index, data] of initialData.entries()) {
      allRows.push({
        fields: templateFields,
        data: data,
        index: index,
      });
    }
    setFieldRows(allRows);
  }, [initialData]);

  return (
    <form className={classes} onSubmit={onSubmit}>
      <div className="form__fields">
        {fieldRows.map((row, _index) => {
          return (
            <div className="form__row" key={_index}>
              {row.fields.map((field, index) => {
                if (readonly) {
                  return (
                    <div key={`${name}-${index}`}>
                      {field.label}: {row.data[field.name] || "-"}
                    </div>
                  );
                }
                return (
                  <FormField key={`${name}-${index}`} name={field.name} label={field.label} helpText={field.helpText} big={field.big} row={field.row}>
                    {getTypedField(field, row.data[field.name], row.index)}
                  </FormField>
                );
              })}
              {!readonly && <Button onClick={deleteRow.bind(deleteRow, row.index)} styleType="danger" icon="FaTrashCan"></Button>}
              <hr className="form__divider" />
            </div>
          );
        })}
      </div>
      {!readonly && (
        <div className="form__actions">
          {children}
          <Button onClick={addedRow} icon="FaPlus"></Button>
          <Button type="submit">{submitText}</Button>
        </div>
      )}
    </form>
  );
};
