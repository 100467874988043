/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type UserRole = typeof UserRole[keyof typeof UserRole];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserRole = {
  User: 'User',
  Admin: 'Admin',
  Admin_Owner: 'Admin_Owner',
  Cheater: 'Cheater',
  Possible_Cheater: 'Possible_Cheater',
} as const;
