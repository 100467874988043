export function getBackendUrls() {
  return import.meta.env.VITE_API_URLS.split(",");
}

export function getBackendUrl() {
  const backend = localStorage.getItem("backend");
  const backends = getBackendUrls();
  if (!backend) {
    return backends[0];
  }
  if (backends.includes(backend)) {
    return backend;
  }
  localStorage.setItem("backend", backends[0]);
  return backends[0];
}
