// eslint-disable-next-line simple-import-sort/imports
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { ColumnDef, getCoreRowModel, getFilteredRowModel, useReactTable } from "@tanstack/react-table";
import { AxiosError } from "axios";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

import { DnDDataTable, RowDragHandleCell } from "@/dnd_datatable";
import { Icon } from "@/icon/icon";
import { IconName } from "@/icon/types";
import { Button } from "@/ui/button";
import { Checkbox } from "@/ui/checkbox";
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/ui/dialog";
import { Input } from "@/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/ui/select";

import { Reward } from "./CollectionsParts/types";

import { HighlightedCollectionType, OfferStatus } from "~/api/model";
import { useUpdateBonusOfferV1OffersBonusOfferOfferIdPut, useUpdateOfferV1OffersOfferIdPut } from "~/api/offers/offers.gen";
import { cn } from "~/helpers";
import { getCategoryIcon } from "~/helpers/category";
import formatDate from "~/helpers/date-formatting";
import { prepareBonusOfferUpdate, prepareOfferUpdate } from "~/helpers/offer";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { CategoryWithStatusAndRewardCount, OfferWithCategoriesAndTags, TagWithStatusAndRewardCount, useMarketplace } from "~/providers/marketplace";
import { useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut, useUpdateTagV1MarketplaceTagsTagIdPut } from "~/api/marketplace/marketplace.gen";
import { ConfirmDialog } from "@/confirm_dialog";

export default function RewardOrderPopup({
  parent,
  parentType,
  refetch,
  openDialog,
  setOpenDialog,
  showDelete,
}: {
  parent: CategoryWithStatusAndRewardCount | TagWithStatusAndRewardCount | null;
  parentType: HighlightedCollectionType;
  refetch: () => void;
  openDialog: boolean;
  setOpenDialog: any;
  showDelete: boolean;
}) {
  if (!parent) return null;

  const { rewards, activeRewards, refetchMarketplace } = useMarketplace();

  const [localState, setLocalState] = useState<string[]>();
  const [rowSelection, setRowSelection] = useState({});
  const [name, setName] = useState<string>(parent.name);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [changeStatusDialogText, setChangeStatusDialogText] = useState("");
  const [changeStatusOffer, setChangeStatusOffer] = useState<OfferWithCategoriesAndTags | null>(null);
  const [changeStatusStatus, setChangeStatusStatus] = useState<OfferStatus | null>(null);

  const [mappedRewards, setMappedRewards] = useState<Reward[]>([]);
  const [columnVisibility, _setColumnVisibility] = useState({
    rewardType: false,
    platform: false,
  });

  const categoryRewardUpdateMutation = useUpdateCategoryV1MarketplaceCategoriesCategoryIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const tagRewardUpdateMutation = useUpdateTagV1MarketplaceTagsTagIdPut({
    mutation: {
      onSuccess: () => {
        toast("Reward updated successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const updateOfferMutation = useUpdateOfferV1OffersOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  const updateBonusOfferMutation = useUpdateBonusOfferV1OffersBonusOfferOfferIdPut({
    mutation: {
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
      onSuccess: () => {
        toast("Bonus Reward updated successfully!");
        refetchMarketplace();
      },
    },
  });

  useEffect(() => {
    setName(parent?.name);
    if (parent.rewardOrder.length > 0 && !localState) {
      setLocalState(parent.rewardOrder);
    } else {
      setLocalState([]);
    }
    if (!openDialog) {
      setRowSelection({});
    }
  }, [parent, openDialog]);

  useEffect(() => {
    if (!localState) {
      setMappedRewards([]);
    } else if (localState.length > 0) {
      setMappedRewards(
        rewards
          .filter((offer: OfferWithCategoriesAndTags) => {
            return localState.includes(offer._id?.toString() || "");
          })
          .sort((a, b) => localState.indexOf(a._id?.toString() || "") - localState.indexOf(b._id?.toString() || ""))
          .map((offer) => {
            return {
              id: offer._id,
              title: `${offer.titleV2} | ${offer.subTitle}`,
              status: offer.status,
              logoUrl: offer.logoUrl || "",
              hideInList: offer.hideInList,
              points: offer.price,
              used: 0,
              available: offer.amount || 0,
              categories: offer.categories.map((category) => getCategoryIcon(category.category)) || [],
              categoryString: offer.categoryIds.join(",") || "",
              tags: offer.tags.map((tag) => tag.name) || [],
              tagString: offer.tags.map((tag) => tag.name).join(",") || "",
              schedule: {
                start: offer.publishDate || "?",
                end: offer.unPublishDate || "?",
              },
              codes: offer.coupon_count,
              verified: offer.verified || 0,
              order: localState.findIndex((offerId: string) => offerId === offer._id),
              rewardType: offer.bonus ? "bonus" : "regular",
              platform: `${offer.platform.android ? "Android" : ""} ${offer.platform.ios ? "iOS" : ""}`,
              deeplink: offer.deeplink,
              offer: offer,
            } as Reward;
          }),
      );
    } else {
      setMappedRewards([]);
    }
  }, [localState, rewards]);

  function updateHideInList(offer: OfferWithCategoriesAndTags, hideInList: boolean) {
    if (!offer._id) return;

    setMappedRewards((offers) => {
      return offers.map((lOffer: any) => {
        if (lOffer.id === offer._id) return { ...lOffer, hideInList: hideInList };
        return lOffer;
      });
    });

    if (offer.isBonus) {
      updateBonusOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareBonusOfferUpdate(offer),
          hideInList: hideInList,
        },
      });
    } else {
      updateOfferMutation.mutateAsync({
        offerId: offer._id,
        data: {
          ...prepareOfferUpdate(offer),
          hideInList: hideInList,
        },
      });
    }
  }

  function prepareStatusUpdate(offer: OfferWithCategoriesAndTags, status: OfferStatus) {
    if (offer.status === status) return;

    let statusText = `Are you sure you want to change the status of the reward "${offer.titleV2}" from <br/>"${offer.status}" to "${status}"?`;

    if (offer.status === OfferStatus.Unpublished && status === OfferStatus.Published) {
      statusText += "|We will also set the publishDate to now and will set the unpublishDate in the future if that was not already the case.";
    }

    if (offer.status === OfferStatus.Published && status === OfferStatus.Unpublished) {
      statusText += "|We will also set the unpublishDate to now if that was not already the case.";
    }

    if (status === OfferStatus.Archived) {
      statusText += "|This will remove the reward from the marketplace. This will set the publish and unpublish date to now.";
    }

    setChangeStatusDialogText(statusText);
    setChangeStatusOffer(offer);
    setChangeStatusStatus(status);
    setShowChangeStatusDialog(true);
  }

  function updateStatus() {
    if (!changeStatusOffer || !changeStatusStatus) return null;
    // TODO: Update the table to reflect the new status without needing to reload the data from the backend
    //
    const additionalUpdateFields: { publishDate?: string; unPublishDate?: string } = {};
    const now = new Date().toISOString();
    const oneMonthLater = new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString();
    if (changeStatusOffer.status === OfferStatus.Unpublished && changeStatusStatus === OfferStatus.Published) {
      additionalUpdateFields["publishDate"] = now;
      if (!changeStatusOffer.unPublishDate || changeStatusOffer.unPublishDate < oneMonthLater) {
        additionalUpdateFields["unPublishDate"] = oneMonthLater;
      }
    }

    if (changeStatusOffer.status === OfferStatus.Published && changeStatusStatus === OfferStatus.Unpublished) {
      additionalUpdateFields["unPublishDate"] = now;
    }

    if (changeStatusStatus === OfferStatus.Archived) {
      additionalUpdateFields["publishDate"] = now;
      additionalUpdateFields["unPublishDate"] = now;
    }

    setMappedRewards((offers) => {
      return offers.map((lOffer: any) => {
        if (lOffer.id === changeStatusOffer._id) return { ...lOffer, status: changeStatusStatus, ...additionalUpdateFields };
        return lOffer;
      });
    });

    if (changeStatusOffer.isBonus) {
      return updateBonusOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: {
          ...prepareBonusOfferUpdate(changeStatusOffer),
          status: changeStatusStatus,
          ...additionalUpdateFields,
        },
      });
    } else {
      return updateOfferMutation.mutateAsync({
        offerId: changeStatusOffer._id as string,
        data: { ...prepareOfferUpdate(changeStatusOffer), status: changeStatusStatus, ...additionalUpdateFields },
      });
    }
  }

  function addToList(id: string) {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (localState) {
      setLocalState([...localState, id]);
    } else {
      setLocalState([id]);
    }
  }

  const rewardColumns = useMemo<ColumnDef<Reward>[]>(
    () => [
      {
        id: "drag-handle",
        header: "",
        cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
        size: 20,
      },
      {
        id: "select",
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && "indeterminate")}
            onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
            aria-label="Select all"
          />
        ),
        cell: ({ row }) => {
          if (parentType === HighlightedCollectionType.Category && row.original.categories.length <= 1) {
            return (
              <Checkbox
                data-title="Reward only has one category. So we can not remove it from this category"
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
                disabled
              />
            );
          }
          return <Checkbox checked={row.getIsSelected()} onCheckedChange={(value) => row.toggleSelected(!!value)} aria-label="Select row" />;
        },
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: "logoUrl",
        header: "",
        cell: ({ row }) => {
          const logo: string = row.getValue("logoUrl");
          if (logo) {
            return <img src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${logo}`} className="h-10 min-h-10 w-10 min-w-10 rounded-full" />;
          }
        },
      },
      {
        accessorKey: "title",
        header: "Reward",
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => {
          return (
            <Select onValueChange={(value) => prepareStatusUpdate(row.original.offer, value as OfferStatus)} defaultValue={row.original.status}>
              <SelectTrigger
                className={cn(
                  "justify-center gap-1 rounded-full bg-archived p-2 text-archived-foreground",
                  row.original.status === "Published" && "bg-published text-published-foreground",
                  row.original.status === "Unpublished" && "bg-unpublished text-unpublished-foreground",
                )}
              >
                <SelectValue placeholder="Select a status" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={OfferStatus.Published}>Published</SelectItem>
                <SelectItem value={OfferStatus.Unpublished}>Unpublished</SelectItem>
                <SelectItem value={OfferStatus.Archived}>Archived</SelectItem>
              </SelectContent>
            </Select>
          );
        },
      },
      {
        accessorKey: "hideInList",
        header: "Hidden",
        cell: ({ row }) => {
          return (
            <Checkbox checked={row.original.hideInList} onCheckedChange={(checked) => updateHideInList(row.original.offer, checked as boolean)} />
          );
        },
      },
      {
        accessorKey: "points",
        header: "Points",
      },
      {
        id: "available",
        header: () => {
          return (
            <div>
              Total Inventory
              <br />
              <small>Available/Claimed</small>
            </div>
          );
        },
        cell: ({ row }) => {
          return (
            <div>
              {row.original.offer.amount}
              <br />
              <small>
                {row.original.offer.amount - (row.original.verified || 0)}/{row.original.verified}
              </small>
            </div>
          );
        },
      },
      {
        id: "codes",
        header: () => {
          return (
            <div>
              Codes
              <br />
              <small>Unused/Used</small>
            </div>
          );
        },
        cell: ({ row }) => (
          <div>
            {row.original.offer.unused_coupon_count}/{(row.original.offer.coupon_count || 0) - (row.original.offer.unused_coupon_count || 0)}
          </div>
        ),
      },
      {
        accessorKey: "categories",
        header: () => "Categories",
        cell: ({ row }) => {
          const categories: IconName[] = row.getValue("categories");
          return (
            <div className="flex items-center gap-4">
              {categories.map((category: IconName) => (
                <div key={category} className="rounded-md bg-accent p-2 text-accent-foreground">
                  <Icon className="h-5 w-5" icon={category} />
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "tags",
        header: () => "Tags",
        cell: ({ row }) => {
          const tags: string[] = row.getValue("tags");
          return tags.map((tag: string) => <div key={tag}>{tag}</div>);
        },
      },
      {
        accessorKey: "schedule",
        header: () => "Schedule",
        cell: ({ row }) => {
          const schedule: { start: string; end: string } = row.getValue("schedule");
          return (
            <div className="flex flex-col gap-2">
              <div>Start: {formatDate(schedule.start) as ReactNode}</div>
              <div>End: {formatDate(schedule.end) as ReactNode}</div>
            </div>
          );
        },
      },
      {
        id: "Delete",
        cell: ({ row }) => {
          if (parentType == HighlightedCollectionType.Category && row.original.categories.length <= 1) {
            return null;
          }
          return (
            <Button
              variant="ghost"
              onClick={() => {
                setLocalState((data) => data?.filter((reward) => reward !== row.id));
                setMappedRewards((data) => data.filter((reward) => reward.id !== row.id));
              }}
            >
              <Icon icon="Close" />
            </Button>
          );
        },
      },
    ],
    [],
  );

  const rewardTable = useReactTable({
    data: mappedRewards,
    columns: rewardColumns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getRowId: (row) => row.id,
    enableRowSelection: (row) => parentType === HighlightedCollectionType.Category && row.original.categories.length > 1,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility: columnVisibility,
      rowSelection: rowSelection,
    },
  });

  function saveRewardChanges() {
    if (parentType === HighlightedCollectionType.Category) {
      categoryRewardUpdateMutation.mutate({
        categoryId: parent?._id as string,
        data: {
          rewardOrder: mappedRewards.map((reward) => reward.id),
        },
      });
    } else {
      tagRewardUpdateMutation.mutate({
        tagId: parent?._id as string,
        data: {
          name: name,
          rewardOrder: mappedRewards.map((reward) => reward.id),
        },
      });
    }
    setTimeout(() => {
      refetch();
    }, 500);
  }

  // reorder rows after drag & drop
  function handleDragEnd(event: DragEndEvent) {
    const { active, over }: { active: any; over: any } = event;

    if (active && over && active.id !== over.id) {
      setLocalState((data) => {
        if (data) {
          const oldIndex = data.findIndex((reward) => reward === active.id);
          const newIndex = data.findIndex((reward) => reward === over.id);
          return arrayMove(data, oldIndex, newIndex);
        }
      });
      setMappedRewards((data) => {
        const oldIndex = data.findIndex((reward) => reward.id === active.id);
        const newIndex = data.findIndex((reward) => reward.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }
  }

  function deleteSelectedRewards() {
    const selectedRows = rewardTable.getSelectedRowModel().rows;

    const selectedIds: string[] = selectedRows.map((row) => row.original.id as string);
    setLocalState((data) => data?.filter((reward) => !selectedIds.includes(reward)));
    setMappedRewards((data) => data.filter((reward) => !selectedIds.includes(reward.id)));
    setRowSelection([]);
  }

  useEffect(() => {
    rewardTable.getColumn("title")?.setFilterValue("");
    if (!openDialog) {
      setLocalState(undefined);
    }
  }, [openDialog]);

  return (
    <div>
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogContent className="max-w-10/12 w-10/12" hideClose={true}>
          <DialogHeader>
            <DialogTitle className="text-bold flex items-center justify-between">
              {parentType === HighlightedCollectionType.Category ? (
                name
              ) : (
                <div className="flex items-center gap-1">
                  <Input value={name} onChange={(event) => setName(event.currentTarget.value)} className="[field-sizing:content]" />
                  <Icon icon="Edit" />
                </div>
              )}
              <div className="flex items-center gap-10">
                <Input
                  placeholder="Search"
                  value={(rewardTable.getColumn("title")?.getFilterValue() as string) || ""}
                  onChange={(event) => {
                    rewardTable.getColumn("title")?.setFilterValue(event.target.value);
                  }}
                />
                <DialogClose asChild>
                  <Button variant={"ghost"}>
                    <Icon icon="Close" />
                  </Button>
                </DialogClose>
              </div>
            </DialogTitle>
          </DialogHeader>
          <div className="overflow-x-auto">
            <div className="max-h-[60dvh] overflow-y-auto">
              <DnDDataTable
                table={rewardTable}
                columns={rewardColumns}
                handleDragEnd={handleDragEnd}
                dataIds={localState || []}
                hidePagination
                additionalData={activeRewards}
                addToList={addToList}
                searchColumn="title"
              />
            </div>
          </div>
          <DialogFooter>
            {Object.keys(rowSelection).length > 0 && (
              <Button variant="destructive" onClick={deleteSelectedRewards}>
                Delete Rewards from {parent?.name}
              </Button>
            )}
            {showDelete && (
              // eslint-disable-next-line no-console
              <Button onClick={() => console.log("delete Tag")} variant={"destructive"}>
                Delete
              </Button>
            )}
            <DialogClose asChild>
              <Button variant={"outline"}>Cancel</Button>
            </DialogClose>
            <Button onClick={saveRewardChanges}>Save</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        openDialog={showChangeStatusDialog}
        setOpenDialog={setShowChangeStatusDialog}
        title={changeStatusDialogText}
        confirmAction={updateStatus}
        confirmText={`set to ${changeStatusStatus}`}
      />
    </div>
  );
}
