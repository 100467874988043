import * as React from "react";
import { useEffect, useState } from "react";
import { QueryObserverResult, RefetchOptions, useSuspenseQuery } from "@tanstack/react-query";

import { IconName } from "@/icon/types";

import { getGetMarketplaceDataV1MarketplaceGetQueryOptions } from "~/api/marketplace/marketplace.gen";
import { CategoryMarketModel, HighlightedCollectionModel, OfferStatus, OfferWithCouponCount, RecommendedModel, TagModel } from "~/api/model";
import { getCategoryIcon, getCategoryName } from "~/helpers/category";

export type OfferWithCategoriesAndTags = OfferWithCouponCount & {
  id: string | undefined;
  fullTitle: string;
  categories: CategoryMarketModel[];
  tags: TagModel[];
  categoryIds: string[];
  categoryIdsString: string;
  tagIds: string[];
  tagNames: string;
  isBonus: boolean;
};
export type CategoryWithStatusAndRewardCount = CategoryMarketModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  name: string;
  icon: IconName;
  rewardOrder: string[];
};
export type TagWithStatusAndRewardCount = TagModel & {
  status: ["recommended", "highlighted"] | ["recommended"] | ["highlighted"] | [];
  rewardCount: number;
  publishedCount: number;
  unpublishedCount: number;
  rewardOrder: string[];
};
export type HighlightedCollectionWithCollection = HighlightedCollectionModel & {
  id: string | undefined;
  collection: CategoryWithStatusAndRewardCount | TagWithStatusAndRewardCount | undefined;
};

export interface MarketplaceContext {
  rewards: OfferWithCategoriesAndTags[];
  activeRewards: OfferWithCategoriesAndTags[];
  categories: CategoryWithStatusAndRewardCount[];
  tags: TagWithStatusAndRewardCount[];
  recommended: RecommendedModel[];
  collections: HighlightedCollectionWithCollection[];
  market: string;
  refetchMarketplace: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<any, Error>>;
  marketplaceLoading: boolean;
}

const MarketplaceContext = React.createContext<MarketplaceContext | null>(null);

export function MarketplaceProvider({ children, market }: { children: React.ReactNode; market: string }) {
  const [rewards, setRewards] = useState<OfferWithCategoriesAndTags[]>([]);
  const [activeRewards, setActiveRewards] = useState<OfferWithCategoriesAndTags[]>([]);
  const [categories, setCategories] = useState<CategoryWithStatusAndRewardCount[]>([]);
  const [tags, setTags] = useState<TagWithStatusAndRewardCount[]>([]);
  const [collections, setCollections] = useState<HighlightedCollectionWithCollection[]>([]);

  const { data, isLoading, refetch } = useSuspenseQuery(getGetMarketplaceDataV1MarketplaceGetQueryOptions({ market: market }));

  function getCOrTStatus(identifier: string, colls: HighlightedCollectionModel[], recs: RecommendedModel[]) {
    const status = [];
    if (colls.find((c: any) => c.data === identifier)) {
      status.push("highlighted");
    }
    if (recs.find((r: any) => r.data === identifier)) {
      status.push("recommended");
    }

    return status;
  }

  function getConnectedCollection(
    identifier: string,
    type: string,
    categories: CategoryWithStatusAndRewardCount[],
    tags: TagWithStatusAndRewardCount[],
  ) {
    if (type === "Category") {
      return categories.find((c) => c.category === identifier);
    } else {
      return tags.find((t) => t._id === identifier);
    }
  }

  useEffect(() => {
    const newRewards = data.offers.map((offer) => {
      const rewardCategories = data.categories.filter((category) => category.rewardOrder.includes(offer._id?.toString() || ""));
      const rewardTags = data.tags
        .filter((tag) => tag.rewardOrder?.includes(offer._id?.toString() || ""))
        .sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...offer,
        id: offer._id,
        fullTitle: `${offer.titleV2} - ${offer.subTitle || ""}`,
        categories: rewardCategories,
        tags: rewardTags,
        categoryIds: rewardCategories.map((c) => c.category),
        categoryIdsString: rewardCategories.map((c) => c.category).join(","),
        tagIds: rewardTags.map((t) => t._id),
        tagNames: rewardTags.map((t) => t.name).join(","),
        isBonus: !!(offer.bonus && offer.bonus.transactionTitle),
      } as OfferWithCategoriesAndTags;
    });
    const newCategories = data.categories
      .map((category) => {
        return {
          ...category,
          name: getCategoryName(category.category),
          icon: getCategoryIcon(category.category),
          status: getCOrTStatus(category.category, data.highlighted_collections, data.recommended),
          rewardCount: category.rewardOrder.length,
          publishedCount: newRewards.filter((reward) => reward.categoryIds.includes(category.category) && reward.status === OfferStatus.Published)
            .length,
          unpublishedCount: newRewards.filter(
            (reward) => reward.categoryIds.includes(category.category || "") && reward.status === OfferStatus.Unpublished,
          ).length,
        } as CategoryWithStatusAndRewardCount;
      })
      .sort((a, b) => a.category.localeCompare(b.category));
    const newTags = data.tags
      .map((tag) => {
        return {
          ...tag,
          status: getCOrTStatus(tag._id?.toString() || "", data.highlighted_collections, data.recommended),
          rewardCount: tag.rewardOrder?.length || 0,
          publishedCount: newRewards.filter((reward) => reward.tagIds.includes(tag._id?.toString() || "") && reward.status === OfferStatus.Published)
            .length,
          unpublishedCount: newRewards.filter(
            (reward) => reward.tagIds.includes(tag._id?.toString() || "") && reward.status === OfferStatus.Unpublished,
          ).length,
        } as TagWithStatusAndRewardCount;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
    const newCollections = data.highlighted_collections.map((highlightedCollection) => {
      return {
        ...highlightedCollection,
        collection: getConnectedCollection(highlightedCollection.data, highlightedCollection.type, newCategories, newTags),
        id: highlightedCollection._id,
      } as HighlightedCollectionWithCollection;
    });
    setRewards(newRewards);
    setActiveRewards(newRewards.filter((reward) => reward.status != OfferStatus.Archived).sort((a, b) => a.titleV2.localeCompare(b.titleV2)));
    setCategories(newCategories);
    setTags(newTags);
    setCollections(newCollections);
  }, [data]);

  return (
    <MarketplaceContext.Provider
      value={{
        rewards: rewards,
        activeRewards: activeRewards,
        categories: categories,
        tags: tags,
        recommended: data.recommended || [],
        collections: collections,
        refetchMarketplace: refetch,
        marketplaceLoading: isLoading,
        market: market,
      }}
    >
      {children}
    </MarketplaceContext.Provider>
  );
}

export function useMarketplace() {
  const context = React.useContext(MarketplaceContext);
  if (!context) {
    throw new Error("useMarketplace must be used within an MarketplaceProvider");
  }
  return context;
}
