/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { toast } from "sonner";

import { Icon } from "./icon/icon";
import { Button } from "./ui/button";

import { useUploadImageV1UploadImagePost } from "~/api/images/images.gen";
import { ImageType } from "~/api/model";
import { cn } from "~/helpers";
import { validateImageDimensions } from "~/helpers/images";
import { parseFastAPIError } from "~/helpers/parse-errors";

const ImagePreviewUploader = React.forwardRef<
  any,
  {
    id?: string;
    imageType: ImageType;
    onChange: (image: string | null) => void;
    defaultImage: string | null;
    className?: string;
  }
>(({ id, imageType, onChange, defaultImage, className }, ref) => {
  let baseAspectClass = "aspect-square";

  if (
    [
      ImageType.offer_thumbnail.toString(),
      ImageType.recommended_image.toString(),
      ImageType.invite_campaign_thumbnail.toString(),
      ImageType.invite_campaign_banner.toString(),
      ImageType.market_banner.toString(),
    ].includes(imageType.toString())
  ) {
    baseAspectClass = "aspect-video";
  }

  const [file, setFile] = useState<Blob | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(defaultImage);

  const uploadImageMutation = useUploadImageV1UploadImagePost({
    mutation: {
      onSuccess: (data) => {
        setUploadedImageUrl(data);
        toast.success("Image uploaded successfully");
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  useEffect(() => {
    setUploadedImageUrl(defaultImage);
  }, [defaultImage]);

  useEffect(() => {
    if (file) {
      handleUpload();
    }
  }, [file]);

  useEffect(() => {
    onChange(uploadedImageUrl);
  }, [uploadedImageUrl]);

  const handleFileChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file || !imageType) {
      toast.error("Error", { description: "Please select a file and an image type" });
      return;
    }

    validateImageDimensions(imageType, file, toast)
      .then(async () => {
        uploadImageMutation.mutate({
          data: {
            file: file,
            image_type: imageType,
          },
        });
      })
      .catch(() => {
        return false;
      });
  };

  const deleteImage = (e: any) => {
    e.preventDefault();
    setUploadedImageUrl(null);
  };

  return (
    <div className="flex items-start gap-4" id={id}>
      <div className="relative">
        <input
          className="absolute h-full w-full cursor-pointer opacity-0"
          type="file"
          required={false}
          accept=".jpg,.jpeg,.png,.gif"
          onChange={handleFileChange}
          ref={ref}
        />
        {uploadedImageUrl && (
          <div className={cn("group relative w-40", baseAspectClass, className)}>
            <Button className="absolute right-3 top-3 hidden p-2 group-hover:block" variant="destructive" type="button" onClick={deleteImage}>
              <Icon icon="Trash" />
            </Button>
            <img
              className="h-full w-full cursor-pointer object-cover"
              src={`${import.meta.env.VITE_ASSETS_DOMAIN}/${uploadedImageUrl}`}
              alt="Uploaded image"
            />
          </div>
        )}
        {!uploadedImageUrl && (
          <div className={cn("flex w-40 flex-col items-center justify-center gap-2 bg-accent text-accent-foreground", baseAspectClass, className)}>
            <Icon icon="Add" />
            Add Image
          </div>
        )}
      </div>
    </div>
  );
});

export default ImagePreviewUploader;
