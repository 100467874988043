const dateFormatting = new Intl.DateTimeFormat("nl-NL", {
  timeZone: "Europe/Stockholm",
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  formatMatcher: "basic",
});

const formatDate = (date: string | Date) => {
  try {
    const newDate = new Date(date);
    return dateFormatting.format(newDate);
  } catch (_error) {
    return date;
  }
};

export default formatDate;

export const convertToUsableDate = (dateValue?: string | Date | null) => {
  if (dateValue === null || dateValue === undefined) {
    return null;
  }
  if (typeof dateValue === "string") {
    return new Date(dateValue);
  }
  return dateValue;
};
