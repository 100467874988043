/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */

export type PartnerStatus = typeof PartnerStatus[keyof typeof PartnerStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PartnerStatus = {
  Published: 'Published',
  Unpublished: 'Unpublished',
  Deleted: 'Deleted',
} as const;
