import { createFileRoute, useNavigate } from "@tanstack/react-router";
import { AxiosError } from "axios";
import { toast } from "sonner";
import { z } from "zod";

import { StepCampaignStatus } from "~/api/model";
import {
  useGetStepCampaignsV1StepCampaignsGet,
  useUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch,
} from "~/api/step-campaigns/step-campaigns.gen";
import { getCountryOptions } from "~/helpers/country-list";
import { parseFastAPIError } from "~/helpers/parse-errors";
import { ListPage } from "~/oldComponents/layout/ListPage";
import { FormConfig, PageConfig, TableConfig } from "~/oldComponents/layout/types";
import { Select } from "~/oldComponents/select/Select";

export const Route = createFileRoute("/_auth/step-campaigns/")({
  // loader: ({ context: { queryClient }, params: { userId } }) => {
  //   return queryClient.ensureQueryData(userQueryOptions(userId));
  // },
  validateSearch: z.object({
    status: z.enum([StepCampaignStatus.Published, StepCampaignStatus.Unpublished, StepCampaignStatus.Deleted]).default(StepCampaignStatus.Published),
    country: z.string().default(""),
  }),
  component: () => <StepCampaignsPage />,
});

function StepCampaignsPage() {
  const search = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const { data: stepCampaigns, isFetching, refetch } = useGetStepCampaignsV1StepCampaignsGet({ country: search.country, status: search.status });

  const updateStepCampaignMutation = useUpdateStepCampaignByIdV1StepCampaignsStepCampaignIdPatch({
    mutation: {
      onSuccess: () => {
        toast("Step campaign was successfully updated!");
        refetch();
      },
      onError: (error: AxiosError) => {
        toast.error("Error", { description: parseFastAPIError(error) });
      },
    },
  });

  const onStatusChange = async (e: React.ChangeEvent<HTMLSelectElement>, stepCampaign: any) => {
    e.preventDefault();
    await updateStepCampaignMutation.mutateAsync({
      stepCampaignId: stepCampaign._id,
      data: {
        ...stepCampaign,
        status: e.target.value,
      },
    });
  };

  const onSearchSubmit = (event: React.FormEvent | undefined) => {
    event?.preventDefault();
  };

  const page: PageConfig = {
    title: "Step campaigns",
    description: `Multiple step campaigns for the same market can be active at the same time.<br>
    The user however will always only see 1 step campaign.<br><br>
         This will either be the step campaign with the closest unPublishDate or the first step campaign found, only published campaigns can be returned.<br>
         Once the publishDate and unpublishDate is set, it will be published and Unpublished automatically.`,
    addLink: "/step-campaigns/create",
    addContent: "Create a new step campaign",
    tableUpload: {
      data: stepCampaigns,
      fileName: "step-campaigns",
    },
  };

  const table: TableConfig = {
    tableBuild: [
      {
        headerTitle: "Country",
        format: (item: any) => item.country,
      },
      {
        headerTitle: "Title",
        format: (item: any) => item.title,
      },
      {
        headerTitle: "Subtitle",
        format: (item: any) => item.subTitle,
      },
      {
        headerTitle: "Reward",
        format: (item: any) => item.reward,
      },
      {
        headerTitle: "Steps divider",
        format: (item: any) => item.stepsDivider,
      },
      {
        headerTitle: "Steps per min",
        format: (item: any) => item.acceptableStepsPerMin || "-",
      },
      {
        headerTitle: "Publish date",
        format: (item: any) => item.publishDate || "-",
      },
      {
        headerTitle: "Unpublish date",
        format: (item: any) => item.unPublishDate || "-",
      },
      {
        headerTitle: "Status",
        actionRow: true,
        format: (item: any) => (
          <Select
            selectedValue={item.status}
            optionList={[
              {
                name: "Published",
                value: "Published",
              },
              {
                name: "Unpublished",
                value: "Unpublished",
              },
              {
                name: "Deleted",
                value: "Deleted",
              },
            ]}
            onChange={(e) => onStatusChange(e, item)}
          />
        ),
      },
    ],
    objects: stepCampaigns,
    link: (item: any) => navigate({ to: "/step-campaigns/$campaignId", params: { campaignId: item._id } }),
    emptyMessage: "There are no steps campaigns in the system yet.",
  };

  const searchBar: FormConfig = {
    name: "partner-app-searchbar",
    fields: [
      {
        name: "country",
        label: "Country:",
        helpText: "Search by campaign country",
        type: "search-select",
        change: (data: string) => navigate({ search: (prev: any) => ({ ...prev, country: data }) }),
        value: search.country,
        options: getCountryOptions(),
        empty: "All",
      },
      {
        name: "status",
        label: "Status:",
        helpText: "Search by campaign status",
        type: "select",
        change: (e: React.ChangeEvent<HTMLSelectElement>) => navigate({ search: (prev: any) => ({ ...prev, status: e.target.value }) }),
        value: search.status,
        options: [
          {
            name: "Published",
            value: "Published",
          },
          {
            name: "Unpublished",
            value: "Unpublished",
          },
          {
            name: "Deleted",
            value: "Deleted",
          },
        ],
      },
    ],
    onSubmit: onSearchSubmit,
    submitText: "Search",
  };

  return <ListPage form={searchBar} loading={isFetching} page={page} table={table} />;
}
