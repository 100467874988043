import { Checkbox } from "./Checkbox";
import { CheckboxItem } from "./types";

import "./checkbox-array.scss";

type Props = {
  checkboxArray: CheckboxItem[] | undefined;
  values: any;
  change: (itemName: string) => void;
};

export const CheckboxArray = ({ checkboxArray, values, change }: Props) => {
  return (
    <>
      {checkboxArray?.map((item) => (
        <div key={item.name} className="checkbox-array__container">
          <Checkbox name={item.name} checked={values[item.name] as boolean} onChange={() => change(item.name)} />
          <label className="form-field__label">{item.label}</label>
          <small className="form-field__tip">{item.description}</small>
        </div>
      ))}
    </>
  );
};
