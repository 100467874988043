/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Stepler API for the Admin
 * This is the documentation for the Stepler Admin.
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  GeneralConfigModel,
  GeneralConfigPatch,
  HTTPValidationError
} from '.././model'
import { axiosInstance } from '.././base';
import type { ErrorType, BodyType } from '.././base';


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


/**
 * Get general config
 * @summary Get General Config
 */
export const getGeneralConfigV1GeneralConfigGet = (
    
 options?: SecondParameter<typeof axiosInstance>,signal?: AbortSignal
) => {
      
      
      return axiosInstance<GeneralConfigModel>(
      {url: `/v1/general_config/`, method: 'GET', signal
    },
      options);
    }
  

export const getGetGeneralConfigV1GeneralConfigGetQueryKey = () => {
    return [`/v1/general_config/`] as const;
    }

    
export const getGetGeneralConfigV1GeneralConfigGetQueryOptions = <TData = Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetGeneralConfigV1GeneralConfigGetQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>> = ({ signal }) => getGeneralConfigV1GeneralConfigGet(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetGeneralConfigV1GeneralConfigGetQueryResult = NonNullable<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>>
export type GetGeneralConfigV1GeneralConfigGetQueryError = ErrorType<unknown>


export function useGetGeneralConfigV1GeneralConfigGet<TData = Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGeneralConfigV1GeneralConfigGet<TData = Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>,
          TError,
          TData
        > , 'initialData'
      >, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetGeneralConfigV1GeneralConfigGet<TData = Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get General Config
 */

export function useGetGeneralConfigV1GeneralConfigGet<TData = Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getGeneralConfigV1GeneralConfigGet>>, TError, TData>>, request?: SecondParameter<typeof axiosInstance>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetGeneralConfigV1GeneralConfigGetQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update general config
 * @summary Update General Config
 */
export const updateGeneralConfigV1GeneralConfigPatch = (
    generalConfigPatch: BodyType<GeneralConfigPatch>,
 options?: SecondParameter<typeof axiosInstance>,) => {
      
      
      return axiosInstance<GeneralConfigModel>(
      {url: `/v1/general_config/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: generalConfigPatch
    },
      options);
    }
  


export const getUpdateGeneralConfigV1GeneralConfigPatchMutationOptions = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>, TError,{data: BodyType<GeneralConfigPatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>, TError,{data: BodyType<GeneralConfigPatch>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>, {data: BodyType<GeneralConfigPatch>}> = (props) => {
          const {data} = props ?? {};

          return  updateGeneralConfigV1GeneralConfigPatch(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type UpdateGeneralConfigV1GeneralConfigPatchMutationResult = NonNullable<Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>>
    export type UpdateGeneralConfigV1GeneralConfigPatchMutationBody = BodyType<GeneralConfigPatch>
    export type UpdateGeneralConfigV1GeneralConfigPatchMutationError = ErrorType<HTTPValidationError>

    /**
 * @summary Update General Config
 */
export const useUpdateGeneralConfigV1GeneralConfigPatch = <TError = ErrorType<HTTPValidationError>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>, TError,{data: BodyType<GeneralConfigPatch>}, TContext>, request?: SecondParameter<typeof axiosInstance>}
): UseMutationResult<
        Awaited<ReturnType<typeof updateGeneralConfigV1GeneralConfigPatch>>,
        TError,
        {data: BodyType<GeneralConfigPatch>},
        TContext
      > => {

      const mutationOptions = getUpdateGeneralConfigV1GeneralConfigPatchMutationOptions(options);

      return useMutation(mutationOptions);
    }
    